import React, {useState} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Spinner } from 'reactstrap';

const PdfGenerator = ({ content }) => {
  const [loading, setLoading] = useState(false);
  const generatePdf = () => {
    setLoading(true);
    const input = document.getElementById('pdf-content');
  
    html2canvas(input)
      .then((canvas) => {
        //const base64image = canvas.toDataURL('image/png');
        //const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height],true);
        //pdf.addImage(base64image, 'PNG', 0, 0, canvas.width, canvas.height);

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [canvas.width, canvas.height], false); // Change 'a4' to the desired page size
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'jpge', 0, 0, pdfWidth, pdfHeight,'FAST');

        pdf.save('generated.pdf');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error generating PDF:', error);
      });
  };

  return (
    <div>
      <div id="pdf-content" className='p-2 mt-1'>
        {content}
      </div>
      <div className='mb-2 text-end'>
        <Button className='text-white mb-1' color='secondary' onClick={generatePdf} disabled={loading}>  Genreate PDF {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
      </div>
    </div>
  );
};

export default PdfGenerator;