import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';
import Select from 'react-select'

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import { FaChevronCircleRight } from "react-icons/fa";

import PdfGenerator from '../../component/utility/PdfGenerator';
import FileSaver from "file-saver";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";


import {
    Row, Col, Card, CardBody, Button, Input, Label, Form, CardFooter, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader
} from 'reactstrap';


const Invoice = () => {
    const navigate = useNavigate();
    const tempItem = React.useRef([]);

    const [loading, setLoading] = useState(false);
    const [itemModal, setItemModal] = useState(false);
    const [transportModal, setTransportModal] = useState(false);

    const [companyOpt, setCompanyOpt] = useState([]);
    const [custOpt, setCustOpt] = useState([]);
    const [productOpt, setProductOpt] = useState([]);
    const [unitOpt, setUnitOpt] = useState([]);
    const [igstOnIntraOpt, setIgstOnIntraOpt] = useState([]);
    const [transactionTypeOpt, setTransactionTypeOpt] = useState([]);
    const [categoryOpt, setCategoryOpt] = useState([]);
    const [reverseChargeOpt, setReverseChargeOpt] = useState([]);
    const [documentTypeOpt, setDocumentTypeOpt] = useState([]);
    const [isServiceOpt, setIsServiceOpt] = useState([]);
    const [transOpt, setTransOpt] = useState([]);
    const [stateOpt, setStateOpt] = useState([]);
    const [transModeOpt, setTransModeOpt] = useState([]);
    const [vehiTypOpt, setVehiTypOpt] = useState([]);
    const [challanOpt, setChallanOpt] = useState([]);


    const [company, setCompany] = useState('101');
    const [category, setCategory] = useState('B2B');
    const [reverseCharge, setReverseCharge] = useState('N');
    const [documentType, setDocumentType] = useState('INV');
    const [documentNumber, setDocumentNumber] = useState(null);
    const [documentDate, setDocumentDate] = useState(null);
    const [challanType, setChallanType] = useState('1');

    const [customerId, setCustomerId] = useState(null);
    const [transpoterId, setTranspoterId] = useState(null);
    const [productId, setProductId] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [gstin, setGstin] = useState(null);
    const [address1, setAddress1] = useState(null);
    const [address2, setAddress2] = useState(null);
    const [location, setLocation] = useState(null);
    const [state, setState] = useState(null);
    const [pincode, setPincode] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNo, setPhoneNo] = useState(null);
    const [placeOfSupply, setPlaceOfSupply] = useState(null);

    const [igstOnIntra, setIgstOnIntra] = useState('N');
    const [transactionType, setTransactionType] = useState('R');

    const [edit, setEdit] = useState(false);
    const [editRow, setEditRow] = useState(null);
    const [product, setProduct] = useState(null);
    const [hsnCode, setHsnCode] = useState(null);
    const [isService, setIsService] = useState('N');
    const [unit, setUnit] = useState('QTL');
    const [qty, setQty] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);
    const [grossAmt, setGrossAmt] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [taxableAmt, setTaxableAmt] = useState(0);
    const [gstRate, setGstRate] = useState(0);
    const [igstAmt, setIgstAmt] = useState(0);
    const [sgstAmt, setSgstAmt] = useState(0);
    const [cgstAmt, setCgstAmt] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);
    const [itemTotalAmt, setItemTotalAmt] = useState(0);

    const [itemList, setItemList] = useState([]);

    const [transId, setTransId] = useState(null);
    const [transName, setTransName] = useState(null);
    const [transMode, setTransMode] = useState(null);
    const [transDocNo, setTransDocNo] = useState(null);
    const [transDocDate, setTransDocDate] = useState(null);
    const [distance, setDistance] = useState(null);
    const [vehicleNo, setVehicleNo] = useState(null);
    const [vehicleType, setVehicleType] = useState(null);

    // dispatch

    const [dispatchFrom, setDispatchFrom] = useState(false);
    const [dispatchModal, setDispatchModal] = useState(false);
    const [disLegalName, setDisLegalName] = useState('');
    const [disAddress1, setDisAddress1] = useState('');
    const [disAddress2, setDisAddress2] = useState('');
    const [disLocation, setDisLocation] = useState('');
    const [disState, setDisState] = useState('');
    const [disPincode, setDisPincode] = useState(null);

    // Ship
    const [shipTo, setShipTo] = useState(false);
    const [shipModal, setShipModal] = useState(false);
    const [shipLegalName, setShipLegalName] = useState('');
    const [shipGstin, setShipGstin] = useState('');
    const [shipAddress1, setShipAddress1] = useState('');
    const [shipAddress2, setShipAddress2] = useState('');
    const [shipLocation, setShipLocation] = useState('');
    const [shipState, setShipState] = useState('');
    const [shipPincode, setShipPincode] = useState(null);

    const [pdfModal, setPdfModal] = useState(false);
    const [totalCalculation, setTotalCalculation] = useState(false);

    useEffect(() => {
        get_init();
    }, []);

    useEffect(() => {
        tempItem.current = itemList;
    }, [itemList]);


    const get_init = () => {

        let list_1 = [
            { value: "Y", label: "YES" },
            { value: "N", label: "NO" },
        ];
        setIgstOnIntraOpt(list_1);
        let list_2 = [
            { value: "R", label: "Regular" },
            { value: "Bill From - Dispatch From", label: "Bill From - Dispatch From" },
            { value: "Bill To - Ship To", label: "Bill To - Ship To" },
            { value: "Combination of 2 & 3", label: "Combination of 2 & 3" },
        ];
        setTransactionTypeOpt(list_2);
        let list_3 = [
            { value: "B2B", label: "B2B" },
            { value: "SEZWP", label: "SEZWP" },
            { value: "SEZWOP", label: "SEZWOP" },
            { value: "EXPWP", label: "EXPWP" },
            { value: "EXPWOP", label: "EXPWOP" },
            { value: "DEXP", label: "DEXP" },
        ];
        setCategoryOpt(list_3);

        let list_4 = [
            { value: "Y", label: "YES" },
            { value: "N", label: "NO" },
        ];
        setReverseChargeOpt(list_4);

        let list_5 = [
            { value: "INV", label: "Tax Invoice" },
            { value: "CRN", label: "Credit Note" },
            { value: "DBN", label: "Debit Note" },
            { value: "BOS", label: "Bill of supply" },
            { value: "CHA", label: "Challan" },
        ];
        setDocumentTypeOpt(list_5);

        let list_6 = [
            { value: "Y", label: "YES" },
            { value: "N", label: "NO" },
        ];
        setIsServiceOpt(list_6);

        let list_7 = [
            { value: '1', label: 'Road' },
            { value: '2', label: 'Rail' },
            { value: '3', label: 'Air' },
            { value: '4', label: 'Ship' },
        ];
        setTransModeOpt(list_7);

        let list_8 = [
            { value: 'R', label: 'Regular' },
            { value: 'O', label: 'ODC' },
        ];
        setVehiTypOpt(list_8);

        let list_9 = [
            { value: '1', label: 'Purchase' },
            { value: '2', label: 'Sale' },
        ];
        setChallanOpt(list_9);

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/state_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return { value: row.state_code, label: row.state_name, dtl: row }
                });
                setStateOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return { value: row.company_code, label: row.name, dtl: row }
                });
                setCompanyOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.product_master_id, label: row.product_name, dtl: row }
                });
                setProductOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/unit_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_3 = response.data.map((row, index) => {
                    return { value: row.uom_code, label: row.uom_desc }
                });
                setUnitOpt(list_3);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //


        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.customer_id, label: row.name, dtl: row }
                });
                setCustOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.transport_master_id, label: row.transport_master_name, dtl: row }
                });
                setTransOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  

    }

    const add_item_handler = () => {
        let item_list = tempItem.current;
        let temp = {
            product: product,
            productId: productId,
            hsnCode: hsnCode,
            isService: isService,
            unit: unit,
            qty: qty,
            unitPrice: unitPrice,
            grossAmt: grossAmt,
            discount: discount,
            taxableAmt: taxableAmt,
            gstRate: gstRate,
            igstAmt: igstAmt,
            sgstAmt: sgstAmt,
            cgstAmt: cgstAmt,
            otherCharges: otherCharges,
            itemTotalAmt: itemTotalAmt,
        }
        item_list.push(temp);

        console.log(item_list);
        //
        let new_item_list = item_list.map((row, index) => {
            return {
                sl: index + 1,
                product: row.product,
                productId: row.productId,
                hsnCode: row.hsnCode,
                isService: row.isService,
                unit: row.unit,
                qty: row.qty,
                unitPrice: row.unitPrice,
                grossAmt: row.grossAmt,
                discount: row.discount,
                taxableAmt: row.taxableAmt,
                gstRate: row.gstRate,
                igstAmt: row.igstAmt,
                sgstAmt: row.sgstAmt,
                cgstAmt: row.cgstAmt,
                otherCharges: row.otherCharges,
                itemTotalAmt: row.itemTotalAmt,
                action: <>
                    <MdDelete className='cursor me-3' color='#fb3a3b' size={20} onClick={() => delete_item_handler(index)} />
                    <FaEdit className='cursor' color='#4aa94c' size={20} onClick={() => edit_item_handler(index, row)} />
                </>
            }
        })
        //

        setItemList(new_item_list);
        setItemModal(false);
        reset_handler();
    }

    const edit_item_handler = (id, row) => {
        setProduct(row.product);
        setProductId(row.productId);
        setHsnCode(row.hsnCode);
        setIsService(row.isService);
        setQty(row.qty);
        setUnitPrice(row.unitPrice);
        setGrossAmt(row.grossAmt);
        setDiscount(row.discount);
        setTaxableAmt(row.taxableAmt);
        setGstRate(row.gstRate);
        setIgstAmt(row.igstAmt);
        setSgstAmt(row.sgstAmt);
        setCgstAmt(row.cgstAmt);
        setOtherCharges(row.otherCharges);
        setItemTotalAmt(row.itemTotalAmt);

        setItemModal(true);
        setEdit(true);
        setEditRow(id);

    }

    const edit_update_handler = () => {
        let item_list = tempItem.current;
        let temp = {
            product: product,
            productId: productId,
            hsnCode: hsnCode,
            isService: isService,
            unit: unit,
            qty: qty,
            unitPrice: unitPrice,
            grossAmt: grossAmt,
            discount: discount,
            taxableAmt: taxableAmt,
            gstRate: gstRate,
            igstAmt: igstAmt,
            sgstAmt: sgstAmt,
            cgstAmt: cgstAmt,
            otherCharges: otherCharges,
            itemTotalAmt: itemTotalAmt,
        }
        item_list.splice(editRow, 1, temp);

        //
        let new_item_list = item_list.map((row, index) => {
            return {
                sl: index + 1,
                product: row.product,
                productId: row.productId,
                hsnCode: row.hsnCode,
                isService: row.isService,
                unit: row.unit,
                qty: row.qty,
                unitPrice: row.unitPrice,
                grossAmt: row.grossAmt,
                discount: row.discount,
                taxableAmt: row.taxableAmt,
                gstRate: row.gstRate,
                igstAmt: row.igstAmt,
                sgstAmt: row.sgstAmt,
                cgstAmt: row.cgstAmt,
                otherCharges: row.otherCharges,
                itemTotalAmt: row.itemTotalAmt,
                action: <>
                    <FaEdit className='cursor me-3' color='#4aa94c' size={20} onClick={() => edit_item_handler(index, row)} />
                    <MdDelete className='cursor' color='#fb3a3b' size={20} onClick={() => delete_item_handler(index)} />
                </>
            }
        })
        //

        setItemList(new_item_list);
        setItemModal(false);
        reset_handler();

    }

    const delete_item_handler = (id) => {

        let item_list = tempItem.current;
        //
        item_list.splice(id, 1);

        let new_item_list = item_list.map((row, index) => {
            return {
                sl: index + 1,
                product: row.product,
                productId: row.productId,
                hsnCode: row.hsnCode,
                isService: row.isService,
                unit: row.unit,
                qty: row.qty,
                unitPrice: row.unitPrice,
                grossAmt: row.grossAmt,
                discount: row.discount,
                taxableAmt: row.taxableAmt,
                gstRate: row.gstRate,
                igstAmt: row.igstAmt,
                sgstAmt: row.sgstAmt,
                cgstAmt: row.cgstAmt,
                otherCharges: row.otherCharges,
                itemTotalAmt: row.itemTotalAmt,
                action: <MdDelete size={20} onClick={() => delete_item_handler(index)} />
            }
        })

        setItemList(new_item_list);
    }

    const reset_handler = () => {
        setProduct('');
        setHsnCode('');
        setQty(0);
        setUnitPrice(0);
        setGrossAmt(0);
        setDiscount(0);
        setTaxableAmt(0);
        setGstRate(0);
        setIgstAmt(0);
        setSgstAmt(0);
        setCgstAmt(0);
        setOtherCharges(0);
        setItemTotalAmt(0);
    }

    const calculation_handler = (val, type) => {
        let unit_price, discount_amt, gst, qantity, other_charges;
        let igst_amt = 0, sgst_amt = 0, cgst_amt = 0, gross_amt = 0, taxable_amt = 0, item_total_amt = 0;
        if (type === 1) {
            setUnitPrice(val);
            unit_price = Number(val).toFixed(3);
            discount_amt = Number(discount).toFixed(3);
            gst = Number(gstRate).toFixed(3);
            qantity = Number(qty).toFixed(3);
            other_charges = Number(otherCharges).toFixed(3);

            //
            gross_amt = Number(unit_price * qantity).toFixed(2);
            taxable_amt = Number((unit_price * qantity) - discount_amt).toFixed(2);

            if (placeOfSupply == 19) {
                sgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
                cgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
            } else {
                igst_amt = Number((taxable_amt * gst) / 100).toFixed(2);
            }
            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 2) {
            setQty(val);
            unit_price = Number(unitPrice).toFixed(3);
            discount_amt = Number(discount).toFixed(3);
            gst = Number(gstRate).toFixed(3);
            qantity = Number(val).toFixed(3);
            other_charges = Number(otherCharges).toFixed(3);

            //
            gross_amt = Number(unit_price * qantity).toFixed(2);
            taxable_amt = Number((unit_price * qantity) - discount_amt).toFixed(2);

            if (placeOfSupply == 19) {
                sgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
                cgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
            } else {
                igst_amt = Number((taxable_amt * gst) / 100).toFixed(2);
            }
            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 3) {
            setDiscount(val);
            unit_price = Number(unitPrice).toFixed(3);
            discount_amt = Number(val).toFixed(3);
            gst = Number(gstRate).toFixed(3);
            qantity = Number(qty).toFixed(3);
            other_charges = Number(otherCharges).toFixed(3);

            //
            gross_amt = Number(unit_price * qantity).toFixed(2);
            taxable_amt = Number((unit_price * qantity) - discount_amt).toFixed(2);

            if (placeOfSupply == 19) {
                sgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
                cgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
            } else {
                igst_amt = Number((taxable_amt * gst) / 100).toFixed(2);
            }
            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 4) {
            setGstRate(val);
            unit_price = Number(unitPrice).toFixed(3);
            discount_amt = Number(discount).toFixed(3);
            gst = Number(val).toFixed(3);
            qantity = Number(qty).toFixed(3);
            other_charges = Number(otherCharges).toFixed(3);

            //
            gross_amt = Number(unit_price * qantity).toFixed(2);
            taxable_amt = Number((unit_price * qantity) - discount_amt).toFixed(2);

            if (placeOfSupply == 19) {
                sgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
                cgst_amt = Number((taxable_amt * (gst / 2)) / 100).toFixed(2);
            } else {
                igst_amt = Number((taxable_amt * gst) / 100).toFixed(2);
            }
            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 5) {
            setSgstAmt(val);

            //
            other_charges = Number(otherCharges).toFixed(3);
            gross_amt = Number(grossAmt).toFixed(3);
            taxable_amt = Number(taxableAmt).toFixed(3);

            sgst_amt = val;
            cgst_amt = cgstAmt;
            igst_amt = igstAmt;

            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 6) {
            setCgstAmt(val);

            //
            other_charges = Number(otherCharges).toFixed(3);
            gross_amt = Number(grossAmt).toFixed(3);
            taxable_amt = Number(taxableAmt).toFixed(3);

            sgst_amt = sgstAmt;
            cgst_amt = val;
            igst_amt = igstAmt;

            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 7) {
            setIgstAmt(val);
            //
            gross_amt = Number(grossAmt).toFixed(3);
            other_charges = Number(otherCharges).toFixed(3);
            taxable_amt = Number(taxableAmt).toFixed(3);

            sgst_amt = sgstAmt;
            cgst_amt = cgstAmt;
            igst_amt = val;

            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 8) {
            setOtherCharges(val);
            unit_price = Number(unitPrice).toFixed(3);
            discount_amt = Number(discount).toFixed(3);
            gst = Number(gstRate).toFixed(3);
            qantity = Number(qty).toFixed(3);
            other_charges = Number(val).toFixed(3);

            //
            gross_amt = Number(grossAmt).toFixed(3);
            taxable_amt = Number(taxableAmt).toFixed(3);

            sgst_amt = sgstAmt;
            cgst_amt = cgstAmt;
            igst_amt = igstAmt;

            item_total_amt = Number(Number(taxable_amt) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt)).toFixed(2);
            //
        } else if (type === 9) {
            setGrossAmt(val);
            //
            gross_amt = val;
            other_charges = Number(otherCharges).toFixed(3);
            taxable_amt = val;

            sgst_amt = sgstAmt;
            cgst_amt = cgstAmt;
            igst_amt = igstAmt;

            item_total_amt = Number(Number(val) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        } else if (type === 10) {
            setTaxableAmt(val);
            //
            gross_amt = grossAmt;
            other_charges = Number(otherCharges).toFixed(3);
            taxable_amt = val;

            sgst_amt = sgstAmt;
            cgst_amt = cgstAmt;
            igst_amt = igstAmt;

            item_total_amt = Number(Number(val) + Number(igst_amt) + Number(sgst_amt) + Number(cgst_amt) ).toFixed(2);
            //
        }

        setGrossAmt(gross_amt);
        setTaxableAmt(taxable_amt);
        setIgstAmt(igst_amt);
        setSgstAmt(sgst_amt);
        setCgstAmt(cgst_amt);
        setItemTotalAmt(item_total_amt);

    }

    const trans_add_handler = (e) => {
        e.preventDefault();

        setTransportModal(false);

    }

    const add_invoice_handler = () => {

        let product = []

        for (let i = 0; i < itemList.length; i++) {
            let list = {
                "product_id": itemList[i].productId,
                "service": itemList[i].isService,
                "qty": itemList[i].qty,
                "rate": itemList[i].unitPrice,
                "unit": itemList[i].unit,
                "discount": itemList[i].discount,
                "gst": itemList[i].gstRate,
                "other_charges": itemList[i].otherCharges,
                "total_amount": itemList[i].itemTotalAmt,

                "taxable_amount": itemList[i].taxableAmt,
                "igst_amount": itemList[i].igstAmt,
                "sgst_amount": itemList[i].sgstAmt,
                "cgst_amount": itemList[i].cgstAmt,
            }
            product.push(list);
        }

        let postData = {
            "company_id": company,
            "category": category,
            "reverse_charge": reverseCharge,
            "doc_type": documentType,
            "doc_no": documentNumber,
            "doc_date": documentDate,
            "challan_type": challanType,

            "customer_id": customerId,
            "customer_name": customerName,
            "cust_gstin": gstin,
            "cust_address_line1": address1,
            "cust_address_line2": address2,
            "cust_location": location,
            "cust_state": state,
            "cust_pincode": pincode,
            "cust_email": email,
            "cust_ph_no": phoneNo,
            "place_of_suppli": placeOfSupply,
            "igst_intra": igstOnIntra,

            "trns_type": transactionType,
            "transpoter_id": transpoterId,
            "transport_mode": transMode,
            "distance": distance,
            "transport_doc_no": transDocNo,
            "transport_doc_date": transDocDate,
            "vehical_no": vehicleNo,
            "vehical_type": vehicleType,

            "product": product,

            "dis_name": disLegalName,
            "dis_addr1": disAddress1,
            "dis_addr2": disAddress2,
            "dis_loc": disLocation,
            "dis_pin": disPincode,
            "dis_state": disState,

            "ship_gstin": shipGstin,
            "ship_name": shipLegalName,
            "ship_addr1": shipAddress1,
            "ship_addr2": shipAddress2,
            "ship_loc": shipLocation,
            "ship_pin": shipPincode,
            "ship_state": shipState

        }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/invoice_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                navigate('/invoice-list');
                let notify = notification({ message: "Invoice added successfull", type: 'success' });
                notify();

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const create_json = () => {

        /* ------------------------------------- ITEM Calculation  ------------------------------------------ */
        let tot_gross_amt = 0, tot_discount_amt = 0, tot_taxable_amt = 0, tot_igst_amt = 0, tot_sgst_amt = 0, tot_cgst_amt = 0, tot_othr_charges = 0, tot_items_amt = 0;
        let items = itemList.map((row, index) => {
            tot_gross_amt = tot_gross_amt + Number(row.grossAmt);
            tot_discount_amt = tot_discount_amt + Number(row.discount);
            tot_taxable_amt = tot_taxable_amt + Number(row.taxableAmt);
            tot_igst_amt = tot_igst_amt + Number(row.igstAmt);
            tot_sgst_amt = tot_sgst_amt + Number(row.sgstAmt);
            tot_cgst_amt = tot_cgst_amt + Number(row.cgstAmt);
            tot_othr_charges = tot_othr_charges + Number(row.otherCharges);
            tot_items_amt = tot_items_amt + Number(row.itemTotalAmt);
            return {
                "SlNo": "" + index + 1,
                "PrdDesc": row.product,
                "IsServc": row.isService,
                "HsnCd": row.hsnCode,
                "Qty": Number(row.qty),
                "FreeQty": 0,
                "Unit": row.unit,
                "UnitPrice": Number(row.unitPrice),
                "TotAmt": Number(row.grossAmt),
                "Discount": Number(row.discount),
                "PreTaxVal": Number(row.taxableAmt),
                "AssAmt": Number(row.taxableAmt),
                "GstRt": Number(row.gstRate),
                "IgstAmt": Number(row.igstAmt),
                "CgstAmt": Number(row.sgstAmt),
                "SgstAmt": Number(row.cgstAmt),
                "CesRt": 0,
                "CesAmt": 0,
                "CesNonAdvlAmt": 0,
                "StateCesRt": 0,
                "StateCesAmt": 0,
                "StateCesNonAdvlAmt": 0,
                "OthChrg": Number(row.otherCharges),
                "TotItemVal": Number(row.itemTotalAmt),
            }
        });
        //
        //
        //genrate json code
        //
        let dispatch_from = `"Nm": "${disLegalName}", "Addr1":  "${disAddress1}", "Addr2":  ${disAddress2 ? `"${disAddress2}"` : null}, "Loc":  "${disLocation}", "Pin": ${disPincode}, "Stcd":  "${disState}"`;
        let ship_to = ` "Gstin": ${shipGstin ? `"${shipGstin}"` : null} , "LglNm":"${shipLegalName}", "Addr1":"${shipAddress1}", "Addr2": ${shipAddress2 ? `"${shipAddress2}"` : null}, "Loc":  "${shipLocation}", "Pin": ${shipPincode}, "Stcd": "${shipState}"`;
        /* ------------------------------------- TAX & Regular ------------------------------------------ */
        if (documentType == 'INV' && transactionType == 'R') {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },

                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "EwbDtls": {
                        "TransId": ${transId ? `"${transId}"` : null},
                        "TransName": ${transName ? `"${transName}"` : null},
                        "TransMode": ${transMode ? `"${transMode}"` : null},
                        "Distance": ${distance},
                        "TransDocNo": ${transDocNo ? `"${transDocNo}"` : null},
                        "TransDocDt": ${transDocDate ? `"${transDocDate.split(" ")[0].split('-').reverse().join('/')}"` : null},
                        "VehNo": ${vehicleNo ? `"${vehicleNo}"` : null},
                        "VehType": ${vehicleType ? `"${vehicleType}"` : null}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- TAX & Dispatch From ------------------------------------------ */
        if (documentType == 'INV' && transactionType == 'Bill From - Dispatch From' && dispatchFrom) {

            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "DispDtls": {${dispatch_from}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "EwbDtls": {
                        "TransId": ${transId ? `"${transId}"` : null},
                        "TransName": ${transName ? `"${transName}"` : null},
                        "TransMode": ${transMode ? `"${transMode}"` : null},
                        "Distance": ${distance},
                        "TransDocNo": ${transDocNo ? `"${transDocNo}"` : null},
                        "TransDocDt": ${transDocDate ? `"${transDocDate.split(" ")[0].split('-').reverse().join('/')}"` : null},
                        "VehNo": ${vehicleNo ? `"${vehicleNo}"` : null},
                        "VehType": ${vehicleType ? `"${vehicleType}"` : null}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- TAX & ship to ------------------------------------------ */
        if (documentType == 'INV' && transactionType == 'Bill To - Ship To' && shipTo) {

            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "ShipDtls": {${ship_to}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "EwbDtls": {
                        "TransId": ${transId ? `"${transId}"` : null},
                        "TransName": ${transName ? `"${transName}"` : null},
                        "TransMode": ${transMode ? `"${transMode}"` : null},
                        "Distance": ${distance},
                        "TransDocNo": ${transDocNo ? `"${transDocNo}"` : null},
                        "TransDocDt": ${transDocDate ? `"${transDocDate.split(" ")[0].split('-').reverse().join('/')}"` : null},
                        "VehNo": ${vehicleNo ? `"${vehicleNo}"` : null},
                        "VehType": ${vehicleType ? `"${vehicleType}"` : null}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- TAX & Combination of 2 & 3 ------------------------------------------ */

        if (documentType == 'INV' && transactionType == 'Combination of 2 & 3' && shipTo && dispatchFrom) {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "DispDtls": {${dispatch_from}},
                      "ShipDtls": {${ship_to}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "EwbDtls": {
                        "TransId": ${transId ? `"${transId}"` : null},
                        "TransName": ${transName ? `"${transName}"` : null},
                        "TransMode": ${transMode ? `"${transMode}"` : null},
                        "Distance": ${distance},
                        "TransDocNo": ${transDocNo ? `"${transDocNo}"` : null},
                        "TransDocDt": ${transDocDate ? `"${transDocDate.split(" ")[0].split('-').reverse().join('/')}"` : null},
                        "VehNo": ${vehicleNo ? `"${vehicleNo}"` : null},
                        "VehType": ${vehicleType ? `"${vehicleType}"` : null}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- Credit / Debit & Regular ------------------------------------------ */
        if ((documentType == 'CRN' || documentType == 'DBN') && transactionType == 'R') {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- Credit / Debit & Dispatch from ------------------------------------------ */
        if ((documentType == 'CRN' || documentType == 'DBN') && transactionType == 'Bill From - Dispatch From' && dispatch_from) {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "DispDtls": {${dispatch_from}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- Credit / Debit & Ship to ------------------------------------------ */
        if ((documentType == 'CRN' || documentType == 'DBN') && transactionType == 'Bill To - Ship To' && shipTo) {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "ShipDtls": {${ship_to}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }
        /* ------------------------------------- Credit / Debit & cobination of 2 and 3 ------------------------------------------ */
        if ((documentType == 'CRN' || documentType == 'DBN') && transactionType == 'Combination of 2 & 3' && shipTo && dispatchFrom) {
            var blob = new Blob([
                `[
                    {
                      "Version": "1.1",
                      "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp": "${category}",
                        "IgstOnIntra": "${igstOnIntra}",
                        "RegRev": "${reverseCharge}",
                        "EcmGstin": null
                      },
                      "DocDtls": {
                        "Typ": "${documentType}",
                        "No": "${documentNumber}",
                        "Dt": "${documentDate.split(" ")[0].split('-').reverse().join('/')}"
                      },
                      "SellerDtls": {
                        "Gstin": "${company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}",
                        "LglNm": "${company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}",
                        "Addr1": "${company == 101 ? "VILL+P.O-PURUNDA,DANTAN,PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}",
                        "Addr2": "${company == 101 ? null : "PURUNDA,DANTAN,PASCHIM MEDINIPUR"}",
                        "Loc": "PURUNDA",
                        "Pin": 721445,
                        "Stcd": "19",
                        "Ph": "9733641119",
                        "Em": null
                      },
                      "BuyerDtls": {
                        "Gstin": "${gstin}",
                        "LglNm": "${customerName}",
                        "Addr1": "${address1}",
                        "Addr2": ${address2 ? `"${address2}"` : null},
                        "Loc": "${location}",
                        "Pin": ${pincode},
                        "Pos": "${placeOfSupply}",
                        "Stcd": "${state}",
                        "Ph": ${phoneNo ? `"${phoneNo}"` : null},
                        "Em": null
                      },
                      "DispDtls": {${dispatch_from}},
                      "ShipDtls": {${ship_to}},
                      "ValDtls": {
                        "AssVal": ${tot_taxable_amt},
                        "IgstVal": ${tot_igst_amt},
                        "CgstVal": ${tot_cgst_amt},
                        "SgstVal": ${tot_sgst_amt},
                        "CesVal": 0,
                        "StCesVal": 0,
                        "Discount": ${tot_discount_amt},
                        "OthChrg": ${tot_othr_charges},
                        "RndOffAmt": 0,
                        "TotInvVal": ${tot_items_amt}
                      },
                      "RefDtls": {
                        "InvRm": "NICGEPP2.0"
                      },
                      "ItemList": ${JSON.stringify(items)}
                    }
                  ]`

            ], {
                type: "json;charset=utf-8"
            });
            FileSaver.saveAs(blob, "invoice_gen.json");
        }


    }

    const gen_pdf_handler = () => {
        let tot_gross_amt = 0, tot_discount_amt = 0, tot_taxable_amt = 0, tot_igst_amt = 0, tot_sgst_amt = 0, tot_cgst_amt = 0, tot_othr_charges = 0, tot_items_amt = 0;

        itemList.map(row => {
            tot_gross_amt = tot_gross_amt + Number(row.grossAmt);
            tot_discount_amt = tot_discount_amt + Number(row.discount);
            tot_taxable_amt = tot_taxable_amt + Number(row.taxableAmt);
            tot_igst_amt = tot_igst_amt + Number(row.igstAmt);
            tot_sgst_amt = tot_sgst_amt + Number(row.sgstAmt);
            tot_cgst_amt = tot_cgst_amt + Number(row.cgstAmt);
            tot_othr_charges = tot_othr_charges + Number(row.otherCharges);
            tot_items_amt = tot_items_amt + Number(row.itemTotalAmt);

        });

        let tot = {
            tot_gross_amt: tot_gross_amt,
            tot_discount_amt: tot_discount_amt,
            tot_taxable_amt: tot_taxable_amt,
            tot_igst_amt: tot_igst_amt,
            tot_sgst_amt: tot_sgst_amt,
            tot_cgst_amt: tot_cgst_amt,
            tot_othr_charges: tot_othr_charges,
            tot_items_amt: tot_items_amt,
        }

        setTotalCalculation(tot);
        setPdfModal(true)
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>

                    <h5 className='text-primary'>Transaction View</h5>
                    <Card className='mb-3'>
                        <CardHeader>Document Details</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={3} className='mb-2'>
                                    <Label> Company</Label>
                                    <Select
                                        options={companyOpt}
                                        onChange={opt => setCompany(opt.value)}
                                        value={companyOpt.filter(opt => opt.value === company)}
                                        placeholder="Select Company"
                                        required
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Category</Label>
                                    <Select
                                        options={categoryOpt}
                                        onChange={opt => setCategory(opt.value)}
                                        value={categoryOpt.filter(opt => opt.value === category)}
                                        placeholder="Select Category"
                                        required
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Reverse Charge</Label>
                                    <Select
                                        options={reverseChargeOpt}
                                        value={reverseChargeOpt.filter(opt => opt.value === reverseCharge)}
                                        onChange={opt => setReverseCharge(opt.value)}
                                        placeholder="Select Reverse Charge"
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Document Type</Label>
                                    <Select
                                        options={documentTypeOpt}
                                        value={documentTypeOpt.filter(opt => opt.value === documentType)}
                                        onChange={opt => setDocumentType(opt.value)}
                                        placeholder="Select Document Type"
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Document Number</Label>
                                    <Input type="text" placeholder='Document Number' onChange={(e) => setDocumentNumber(e.target.value)} required />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Document Date</Label>
                                    <Input type="date" placeholder='Document Date' onChange={(e) => setDocumentDate(e.target.value)} required />
                                </Col>

                                {(documentType == 'BOS') &&
                                    <>
                                        <Col xs={3} className='mb-2'>
                                            <Label>Way Bill No.</Label>
                                            <Input type="text" value={transDocNo} placeholder='Trans Doc No' onChange={(e) => setTransDocNo(e.target.value)} />
                                        </Col>
                                        <Col xs={3} className='mb-2'>
                                            <Label>Vehicle No</Label>
                                            <Input type="text" value={vehicleNo} placeholder='Vehicle No' onChange={(e) => setVehicleNo(e.target.value)} />
                                        </Col>
                                    </>
                                }
                                {(documentType == 'CHA') &&
                                    <>
                                        <Col xs={3} className='mb-2'>
                                            <Label>Vehicle No</Label>
                                            <Input type="text" value={vehicleNo} placeholder='Vehicle No' onChange={(e) => setVehicleNo(e.target.value)} />
                                        </Col>
                                        <Col xs={3} className='mb-2'>
                                            <Label> Challan Type</Label>
                                            <Select
                                                options={challanOpt}
                                                value={challanOpt.filter(opt => opt.value === challanType)}
                                                onChange={opt => setChallanType(opt.value)}
                                                placeholder="Select Challan Type"
                                            />
                                        </Col>
                                    </>
                                }

                            </Row>


                        </CardBody>
                    </Card>
                    <Card className='mb-3'>
                        <CardHeader>Bill To Details</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={3} className='mb-2'>
                                    <Label>Customer Name*</Label>
                                    <Select
                                        options={custOpt}
                                        onChange={opt => {
                                            setCustomerId(opt.value);
                                            setCustomerName(opt.label);
                                            setAddress1(opt.dtl.address1);     
                                            setAddress2(opt.dtl.address2);
                                            setLocation(opt.dtl.location);
                                            setState(opt.dtl.state);
                                            setPincode(opt.dtl.pincode);
                                            setGstin(opt.dtl.gst_no);
                                            setEmail(opt.dtl.email);
                                            setPhoneNo(opt.dtl.phone_no);
                                        }}
                                        placeholder="Select Customer Name"
                                        required
                                    />
                                </Col>
                                { (customerId == 0 && documentType == 'BOS') &&
                                    <Col xs={3} className='mb-2'>
                                        <Label> Un-registered Customer Name*</Label>
                                        <Input type="text" placeholder='Customer Name' value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
                                    </Col>
                                }
                                <Col xs={3} className='mb-2'>
                                    <Label> GSTIN*</Label>
                                    <Input type="text" placeholder='GSTIN' value={gstin} onChange={(e) => setGstin(e.target.value)} required />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Address Line - 1*</Label>
                                    <Input type="text" placeholder='Address Line - 1' value={address1} onChange={(e) => setAddress1(e.target.value)} required />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Address Line - 2</Label>
                                    <Input type="text" placeholder='Address Line - 2' value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Location*</Label>
                                    <Input type="text" placeholder='Location' value={location} onChange={(e) => setLocation(e.target.value)} required />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> State*</Label>
                                    <Select
                                        options={stateOpt}
                                        value={stateOpt.filter(opt => opt.value === state)}
                                        onChange={opt => setState(opt.value)}
                                        placeholder="Select State"
                                        required
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Pincode*</Label>
                                    <Input type="text" placeholder='Pincode' value={pincode} onChange={(e) => setPincode(e.target.value)} required />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Email</Label>
                                    <Input type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Phone No</Label>
                                    <Input type="number" placeholder='Phone No' value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label> Place of Supply*</Label>

                                    <Select
                                        options={stateOpt}
                                        onChange={opt => setPlaceOfSupply(opt.value)}
                                        placeholder="Select Place of Supply"
                                        required
                                    />

                                </Col>

                                <hr />

                                <Col xs={3} className='mb-2'>
                                    <Label>Igst on Intra</Label>
                                    <Select
                                        options={igstOnIntraOpt}
                                        value={igstOnIntraOpt.filter(opt => opt.value === igstOnIntra)}
                                        onChange={opt => setIgstOnIntra(opt.value)}
                                        placeholder="Select Igst on Intra"
                                    />
                                </Col>
                                <Col xs={3} className='mb-2'>
                                    <Label>Transaction Type</Label>
                                    <Select
                                        options={transactionTypeOpt}
                                        value={transactionTypeOpt.filter(opt => opt.value === transactionType)}
                                        onChange={opt => setTransactionType(opt.value)}
                                        placeholder="Select Transaction Type"
                                    />
                                </Col>
                                <Col xs={6} className='mb-2 mt-1'>
                                    {transactionType == 'Bill From - Dispatch From' &&
                                        <Button className='text-white me-5 mt-4' color='primary' onClick={() => setDispatchModal(true)}> Dispatch From </Button>
                                    }
                                    {transactionType == 'Bill To - Ship To' &&
                                        <Button className='text-white me-5 mt-4' color='primary' onClick={() => setShipModal(true)}> Ship To </Button>
                                    }
                                    {transactionType == 'Combination of 2 & 3' &&
                                        <>
                                            <Button className='text-white me-5 mt-4' color='primary' onClick={() => setDispatchModal(true)}> Dispatch From </Button>
                                            <Button className='text-white me-5 mt-4' color='primary' onClick={() => setShipModal(true)}> Ship To </Button>
                                        </>
                                    }
                                </Col>

                            </Row>


                        </CardBody>
                        <CardFooter className='text-end'>
                            {(documentType == 'INV') &&
                                <Button className='text-white me-5' color='primary' onClick={() => setTransportModal(true)}> E-way Bill Detail </Button>
                            }

                            <Button className='text-white' color='primary' onClick={() => {
                                if (placeOfSupply > 0) {
                                    setEdit(false);
                                    reset_handler();
                                    setItemModal(true);
                                } else {
                                    let notify = notification({ message: "Select Place of supply", type: 'warn' });
                                    notify();
                                }
                            }}> Add New Item </Button>

                        </CardFooter>
                    </Card>

                    <Card className='mb-3'>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: '80px'
                                    },
                                    {
                                        name: 'Product Description',
                                        selector: row => row.product,
                                        wrap: true
                                    },
                                    {
                                        name: 'Quantity',
                                        selector: row => row.qty,
                                        wrap: true
                                    },
                                    {
                                        name: 'Price / Unit',
                                        selector: row => row.unitPrice,
                                        wrap: true
                                    },
                                    {
                                        name: 'Discount',
                                        selector: row => row.discount,
                                        wrap: true
                                    },

                                    {
                                        name: 'Taxable Amount',
                                        selector: row => row.taxableAmt,
                                        wrap: true
                                    },

                                    {
                                        name: 'GST Rate',
                                        selector: row => row.gstRate,
                                        wrap: true
                                    },
                                    {
                                        name: 'IGST',
                                        selector: row => row.igstAmt,
                                        wrap: true
                                    },
                                    {
                                        name: 'SGST',
                                        selector: row => row.sgstAmt,
                                        wrap: true
                                    },
                                    {
                                        name: 'CGST',
                                        selector: row => row.cgstAmt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Other Charges',
                                        selector: row => row.otherCharges,
                                        wrap: true
                                    },
                                    {
                                        name: 'Item Total',
                                        selector: row => row.itemTotalAmt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Action',
                                        selector: row => row.action,
                                        wrap: true
                                    },

                                ]}
                                data={itemList}
                                striped
                                highlightOnHover
                            />
                        </CardBody>
                        <CardFooter className='text-end'>

                            <Button className='text-white' color='primary' onClick={gen_pdf_handler}> Review & Submit </Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Modal
                centered
                scrollable

                size="lg"
                isOpen={itemModal}
                toggle={() => setItemModal(!itemModal)}
            >
                <ModalHeader toggle={() => setItemModal(!itemModal)}>
                    {
                        !edit ? 'Add New Product' : 'Edit Product'
                    }
                    
                </ModalHeader>
                <ModalBody className='' >
                    <Row>
                        <Col xs={6} className='mb-2'>
                            <Label>Product</Label>
                            <Select
                                options={productOpt}
                                value={productOpt.filter(opt => opt.value === productId)}
                                onChange={opt => {
                                    setProductId(opt.value);
                                    setProduct(opt.label);
                                    setHsnCode(opt.dtl.product_code);
                                    setGstRate(opt.dtl.gst)
                                }}
                                placeholder="Select Product"
                            />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Is Service</Label>
                            <Select
                                options={isServiceOpt}
                                value={isServiceOpt.filter(opt => opt.value === isService)}
                                onChange={opt => setIsService(opt.value)}
                                placeholder="Select Is Service"
                            />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Unit</Label>
                            <Select
                                options={unitOpt}
                                value={unitOpt.filter(opt => opt.value === unit)}
                                onChange={opt => setUnit(opt.value)}
                                placeholder="Select Unit"
                            />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Quantity</Label>
                            <Input type="number" value={qty} placeholder='Quantity' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 2)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Unit Price</Label>
                            <Input type="number" value={unitPrice} placeholder='Unit Price' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 1)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Gross Amount</Label>
                            <Input type="number" value={grossAmt} placeholder='Gross Amount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 9)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Discount</Label>
                            <Input type="number" value={discount} placeholder='Discount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 3)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Taxable Amount</Label>
                            <Input type="number" value={taxableAmt} placeholder='Taxable Amount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 10)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>GST Rate(%)</Label>
                            <Input type="number" value={gstRate} placeholder='GST Rate in %' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 4)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>SGST Amount</Label>
                            <Input type="number" value={sgstAmt} placeholder='SGST Amount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 5)} required />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>CGST Amount</Label>
                            <Input type="number" value={cgstAmt} placeholder='CGST Amount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 6)} required />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>IGST Amount</Label>
                            <Input type="number" value={igstAmt} placeholder='IGST Amount' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 7)} required />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Other Charges</Label>
                            <Input type="number" value={otherCharges} placeholder='Other Charges' min="0.00" step="0.001" onChange={(e) => calculation_handler(e.target.value, 8)} />
                        </Col>
                        <Col xs={6} className='mb-2'>
                            <Label>Total Item Amount</Label>
                            <Input type="number" value={documentType == 'BOS' ? taxableAmt : itemTotalAmt} placeholder='Total Item Amount' onChange={(e) => setItemTotalAmt(e.target.value)} readOnly />
                        </Col>
                        <Col xs={12} className='mb-2'>
                            {
                                !edit ?
                                    <Button className='text-white me-5' color='primary' onClick={add_item_handler}> Add </Button>
                                    :
                                    <Button className='text-white me-5' color='primary' onClick={edit_update_handler}> Update </Button>

                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal
                centered
                scrollable

                size="lg"
                isOpen={transportModal}
                toggle={() => setTransportModal(!transportModal)}
            >
                <ModalHeader toggle={() => setTransportModal(!transportModal)}>
                    E-Way Bill Details
                </ModalHeader>
                <ModalBody className='' >
                    <Form onSubmit={trans_add_handler}>

                        <Row>
                            <Col xs={6} className='mb-2'>
                                <Label>Trans Name</Label>
                                <Select
                                    options={transOpt}
                                    value={transOpt.filter(opt => opt.value === transpoterId)}
                                    onChange={opt => { setTransName(opt.label); setTranspoterId(opt.value); setTransId(opt.dtl.gstin) }}
                                    placeholder="Select Trans Name"

                                />
                            </Col>

                            <Col xs={6} className='mb-2'>
                                <Label>Trans Id</Label>
                                <Input type="text" value={transId} placeholder='Trans Id' onChange={(e) => setTransId(e.target.value)} />
                            </Col>


                            <Col xs={6} className='mb-2'>
                                <Label>Trans Mode</Label>
                                <Select
                                    options={transModeOpt}
                                    value={transModeOpt.filter(opt => opt.value === transMode)}
                                    onChange={opt => setTransMode(opt.value)}
                                    placeholder="Select Trans Mode"

                                />
                            </Col>

                            <Col xs={6} className='mb-2'>
                                <Label>Distance*</Label>
                                <Input type="number" value={distance} placeholder='Distance' onChange={(e) => setDistance(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label>Trans Doc No</Label>
                                <Input type="text" value={transDocNo} placeholder='Trans Doc No' onChange={(e) => setTransDocNo(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label>Trans Doc Date</Label>
                                <Input type="date" value={transDocDate} placeholder='Trans Doc Date' onChange={(e) => setTransDocDate(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label>Vehicle No</Label>
                                <Input type="text" value={vehicleNo} placeholder='Vehicle No' onChange={(e) => setVehicleNo(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label>Vehicle Type</Label>
                                <Select
                                    options={vehiTypOpt}
                                    value={vehiTypOpt.filter(opt => opt.value === vehicleType)}
                                    onChange={opt => setVehicleType(opt.value)}
                                    placeholder="Select Vehicle Type"

                                />
                            </Col>

                            <Col xs={12} className='mb-2'>
                                <Button className='text-white me-5' color='primary' > Add </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal
                centered
                scrollable

                size="md"
                isOpen={dispatchModal}
                toggle={() => setDispatchModal(!dispatchModal)}
            >
                <ModalHeader toggle={() => setDispatchModal(!dispatchModal)}>
                    Dispatch From
                </ModalHeader>
                <ModalBody className='' >
                    <Form onSubmit={(e) => { e.preventDefault(); setDispatchFrom(true); setDispatchModal(false); }}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label>Legal Name*</Label>
                                <Select
                                    options={custOpt}
                                    value={custOpt.filter(opt => opt.label === disLegalName)}
                                    onChange={opt => {
                                        setDisLegalName(opt.label);
                                        setDisAddress1(opt.dtl.address1);
                                        setDisAddress2(opt.dtl.address1);
                                        setDisLocation(opt.dtl.location);
                                        setDisState(opt.dtl.state);
                                        setDisPincode(opt.dtl.pincode);

                                    }}
                                    placeholder="Select Customer Name"
                                    required
                                />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Address Line - 1*</Label>
                                <Input type="text" placeholder='Address Line - 1' value={disAddress1} onChange={(e) => setDisAddress1(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Address Line - 2</Label>
                                <Input type="text" placeholder='Address Line - 2' value={disAddress2} onChange={(e) => setDisAddress2(e.target.value)} />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Location*</Label>
                                <Input type="text" placeholder='Location' value={disLocation} onChange={(e) => setDisLocation(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> State*</Label>
                                <Select
                                    options={stateOpt}
                                    value={stateOpt.filter(opt => opt.value === disState)}
                                    onChange={opt => setDisState(opt.value)}
                                    placeholder="Select State"
                                    required
                                />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Pincode*</Label>
                                <Input type="text" placeholder='Pincode' value={disPincode} onChange={(e) => setDisPincode(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Button className='text-white me-5' block color='primary'> Add </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>

            </Modal>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={shipModal}
                toggle={() => setShipModal(!shipModal)}
            >
                <ModalHeader toggle={() => setShipModal(!shipModal)}>
                    Ship To Details
                </ModalHeader>
                <ModalBody className='' >
                    <Form onSubmit={(e) => { e.preventDefault(); setShipTo(true); setShipModal(false); }}>

                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label>Legal Name*</Label>
                                <Select
                                    options={custOpt}
                                    value={custOpt.filter(opt => opt.label === shipLegalName)}
                                    onChange={opt => {
                                        setShipLegalName(opt.label);
                                        setShipAddress1(opt.dtl.address1);
                                        setShipAddress2(opt.dtl.address1);
                                        setShipLocation(opt.dtl.location);
                                        setShipState(opt.dtl.state);
                                        setShipPincode(opt.dtl.pincode);
                                        setShipGstin(opt.dtl.gst_no);

                                    }}
                                    placeholder="Select Customer Name"
                                    required
                                />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> GSTIN*</Label>
                                <Input type="text" placeholder='GSTIN' value={shipGstin} onChange={(e) => setShipGstin(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Address Line - 1*</Label>
                                <Input type="text" placeholder='Address Line - 1' value={shipAddress1} onChange={(e) => setShipAddress1(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Address Line - 2</Label>
                                <Input type="text" placeholder='Address Line - 2' value={shipAddress2} onChange={(e) => setShipAddress2(e.target.value)} />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Location*</Label>
                                <Input type="text" placeholder='Location' value={shipLocation} onChange={(e) => setShipLocation(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> State*</Label>
                                <Select
                                    options={stateOpt}
                                    value={stateOpt.filter(opt => opt.value === shipState)}
                                    onChange={opt => setShipState(opt.value)}
                                    placeholder="Select State"
                                    required
                                />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label> Pincode*</Label>
                                <Input type="text" placeholder='Pincode' value={shipPincode} onChange={(e) => setShipPincode(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Button className='text-white me-5' block color='primary'> Add </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal
                centered
                scrollable
                size="xl"
                isOpen={pdfModal}
                toggle={() => setPdfModal(!pdfModal)}
            >
                <ModalHeader toggle={() => setPdfModal(!pdfModal)}>
                    Invoice PDF
                </ModalHeader>
                <ModalBody className='' >
                    {
                        documentType === 'BOS' ?
                            <PdfGenerator content={
                                <div className='mt-4 m-2 ' >
                                    <Row className={company == 101 ? 'bgpink' : 'bggreen'} style={{ border: '2px solid #000' }}>
                                        <div className='mb-3 mt-3 text-center'><h6>PURCHASE BILL OF SUPPLY</h6></div>
                                        <Col xs={12}>
                                            <h5>{company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</h5>
                                            <div>{company == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                            <div>{company == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                            <div>WEST BENGAL - 721445</div>
                                            <div>Phone No. : 9733641119</div>
                                        </Col>
                                        <hr />
                                        <hr />
                                        <Col xs={6}>
                                            <div><b>Place of Supply : </b>{address1}  {address2 ? address2 : ''}</div>
                                            <div><b>GSTIN : </b> {company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</div>
                                            <div><b>Document No. : </b>  {documentNumber}</div>
                                            <div><b>Document Date : </b>  {documentDate?.split('-').reverse().join('/')}</div>
                                            <div><b>Way Bill No. : </b>  {transDocNo}</div>
                                            <div><b>Vehicle No. : </b>  {vehicleNo}</div>

                                        </Col>
                                        <Col xs={6}>
                                            <div><b>Vendor GSTIN : </b> {gstin}</div>
                                            <div><b>Vendor Name : </b>{customerName}</div>
                                            <div><b>Vendor Address : </b>{address1}  {address2 ? address2 : ''}</div>
                                            <div><b>State : </b>{stateOpt.filter(opt => opt.value === state)?.[0]?.label} - {pincode}</div>
                                            <div><b>Phone No. : </b>{phoneNo ? phoneNo : ''}</div>
                                            <div><b>Verndor Type : UNREGISTERED DEALER</b></div>
                                        </Col>
                                        <hr />
                                        <div className='pdf_body'>
                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>#</b></td>
                                                        <td><b>Product Decription</b></td>
                                                        <td><b>HSN</b></td>
                                                        <td><b>Qty</b></td>
                                                        <td><b>Rate</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Amount</b></td>
                                                        <td><b>GST(%)</b></td>
                                                        <td><b>IGST</b></td>
                                                        <td><b>SGST</b></td>
                                                        <td><b>CGST</b></td>
                                                        <td><b>Oth Charge</b></td>
                                                        <td><b>Item Total</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        itemList.map((row, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.product}</td>
                                                                    <td>{row.hsnCode}</td>
                                                                    <td>{row.qty} {unitOpt.filter(opt => opt.value === unit)[0].label}</td>
                                                                    <td>{row.unitPrice}</td>
                                                                    <td>{row.discount}</td>
                                                                    <td>{row.taxableAmt}</td>
                                                                    <td>{row.gstRate}</td>
                                                                    <td>{row.igstAmt}</td>
                                                                    <td>{row.sgstAmt}</td>
                                                                    <td>{row.cgstAmt}</td>
                                                                    <td>{row.otherCharges}</td>
                                                                    <td>{row.taxableAmt}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            <h6><b>TAX Under REVERSE CHARGE</b></h6>

                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>Tax'ble Amt</b></td>
                                                        <td><b>CGST Amount</b></td>
                                                        <td><b>SGST Amount</b></td>
                                                        <td><b>IGST Amount</b></td>
                                                        <td><b>CESS Amount</b></td>
                                                        <td><b>State CESS Amount</b></td>
                                                        <td><b>Total Taxes</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Other Charges</b></td>
                                                        <td><b>Total Bill Amount</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{totalCalculation?.tot_taxable_amt}</td>
                                                        <td>{totalCalculation?.tot_cgst_amt}</td>
                                                        <td>{totalCalculation?.tot_sgst_amt}</td>
                                                        <td>{totalCalculation?.tot_igst_amt}</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>{totalCalculation?.tot_cgst_amt + totalCalculation?.tot_sgst_amt + totalCalculation?.tot_igst_amt}</td>
                                                        <td>{totalCalculation?.tot_discount_amt}</td>
                                                        <td>{totalCalculation?.tot_othr_charges}</td>
                                                        <td>{totalCalculation?.tot_taxable_amt}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>

                                    <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>


                                </div>
                            } />

                            : documentType === 'CHA' ? <PdfGenerator content={
                                <div>
                                    <div className='mb-3 mt-4 text-center'><h4>CHALLAN</h4></div>
                                    <Row className={company == 101 ? 'bgpink' : 'bggreen'} style={{ border: '2px solid #000' }}>
                                        <Col xs="4"><b>GSTIN: </b> {company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</Col>
                                        <Col xs="4" className='text-center'><h6>{challanType === "1" ? "PURCHASE BILL" : "SALE BILL"}</h6></Col>
                                        <Col xs="4" className='text-end'><b>ORIGINAL</b></Col>
                                        <Col xs={12} className='text-center'>
                                            <h5>{company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</h5>
                                            <div>{company == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                            <div>{company == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                            <div>WEST BENGAL - 721445</div>
                                            <div>Phone No. : 9733641119</div>
                                        </Col>
                                        <hr />
                                        <Col xs="4"><b>Bill NO.: </b> {documentNumber}</Col>
                                        <Col xs="4"><b>Bill Date: </b> {documentDate?.split('-').reverse().join('/')}</Col>
                                        <Col xs="4"><b>Vehicle No.: </b> {vehicleNo}</Col>
                                        <hr />
                                        <Col xs={12}>
                                            <div><b>Vendor Name : </b> {customerName}</div>
                                            <div><b>Vendor Address : </b> {address1}  {address2 ? address2 : ''}, {stateOpt.filter(opt => opt.value === state)?.[0]?.label} - {pincode} </div>
                                            <div><b>Phone No.: </b> {phoneNo ? phoneNo : ''}</div>
                                        </Col>
                                        <hr />
                                        <div className='pdf_body'>
                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>Sl.No.</b></td>
                                                        <td><b>Product Decription</b></td>
                                                        <td><b>HSN</b></td>
                                                        <td><b>Qty</b></td>
                                                        <td><b>Rate</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Amount</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        itemList.map((row, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.product}</td>
                                                                    <td>{row.hsnCode}</td>
                                                                    <td>{row.qty} {unitOpt.filter(opt => opt.value === unit)[0].label}</td>
                                                                    <td>{row.unitPrice}</td>
                                                                    <td>{row.discount}</td>
                                                                    <td>{row.taxableAmt}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className=' mb-3 text-end'><b>Total Amount: </b> {Number(totalCalculation?.tot_taxable_amt).toFixed(2)}</div>
                                        </div>
                                    </Row>
                                    <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>

                                </div>
                            } />
                                :

                                <PdfGenerator content={
                                    <div>
                                        <div className='mb-3 mt-4 text-center'><h4>INVOICE</h4></div>

                                        <Row>
                                            <Col xs={12}>
                                                <div className='pdf_header'>Document Details</div>
                                                <div className='pdf_body'>
                                                    <Row>
                                                        <Col xs={4} className="mb-3">
                                                            Category :  {category}
                                                        </Col>
                                                        <Col xs={4} className="mb-3">
                                                            Document No. :  {documentNumber}
                                                        </Col>
                                                        <Col xs={4} className="mb-3">
                                                            IGST on INTRA :  {igstOnIntra == 'N' ? "No" : 'Yes'}
                                                        </Col>
                                                        <Col xs={4} className="mb-3">
                                                            Document Type :  {documentType == 'INV' ? "Tax Invoice" : documentType == 'CRN' ? "Credit Note" : documentType == 'DBN' ? "Debit Note" : "Bill of supply"}
                                                        </Col>
                                                        <Col xs={4} className="mb-3">
                                                            Document Date :  {documentDate?.split('-').reverse().join('/')}
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='pdf_header'>Party Details</div>
                                                <div className='pdf_body'>
                                                    <Row>
                                                        <Col xs={6} className="mb-3" >
                                                            <div className='p-3 borderBlack h-100'>
                                                                <div><b>Seller</b></div>
                                                                <div>GSTIN : {company == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</div>

                                                                <div>{company == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</div>
                                                                <div>{company == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                                                <div>{company == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                                                <div>WEST BENGAL - 721445</div>
                                                                <div>9733641119</div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} className="mb-3">
                                                            <div className='p-3 borderBlack h-100'>
                                                                <div><b>Purchaser</b></div>
                                                                <div>GSTIN : {gstin}</div>
                                                                <div>{customerName}</div>
                                                                <div>{address1}</div>
                                                                <div>{address2 ? address2 : ''}</div>
                                                                <div>{stateOpt.filter(opt => opt.value === state)?.[0]?.label} - {pincode}</div>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div>{phoneNo ? phoneNo : ''}</div>
                                                                    <div><b>Place of Supply : </b>{stateOpt.filter(opt => opt.value === placeOfSupply)?.[0]?.label}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        {dispatchFrom &&

                                                            <Col xs={6} className="mb-3">
                                                                <div className='p-3 borderBlack h-100'>
                                                                    <div><b>Dispatch From</b></div>
                                                                    <div>{disLegalName}</div>
                                                                    <div>{disAddress1}</div>
                                                                    <div>{disAddress2 ? disAddress2 : ''}</div>
                                                                    <div>{stateOpt.filter(opt => opt.value === disState)?.[0]?.label} - {disPincode}</div>

                                                                </div>
                                                            </Col>
                                                        }
                                                        {shipTo &&

                                                            <Col xs={6} className="mb-3">
                                                                <div className='p-3 borderBlack h-100'>
                                                                    <div><b>Ship To</b></div>
                                                                    <div>GSTIN : {shipGstin}</div>
                                                                    <div>{shipLegalName}</div>
                                                                    <div>{shipAddress1}</div>
                                                                    <div>{shipAddress2 ? shipAddress2 : ''}</div>
                                                                    <div>{stateOpt.filter(opt => opt.value === shipState)?.[0]?.label} - {shipPincode}</div>

                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>

                                                </div>
                                                <div className='pdf_header'>Item Details</div>
                                                <div className='pdf_body'>
                                                    <table className='mb-3' border="1" width="100%">
                                                        <thead>
                                                            <tr>
                                                                <td><b>#</b></td>
                                                                <td><b>Product Decription</b></td>
                                                                <td><b>HSN</b></td>
                                                                <td><b>Qty</b></td>
                                                                <td><b>Rate</b></td>
                                                                <td><b>Discount</b></td>
                                                                <td><b>Amount</b></td>
                                                                <td><b>GST(%)</b></td>
                                                                <td><b>IGST</b></td>
                                                                <td><b>SGST</b></td>
                                                                <td><b>CGST</b></td>
                                                                <td><b>Oth Charge</b></td>
                                                                <td><b>Item Total</b></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                itemList.map((row, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{row.product}</td>
                                                                            <td>{row.hsnCode}</td>
                                                                            <td>{row.qty}</td>
                                                                            <td>{row.unitPrice}</td>
                                                                            <td>{row.discount}</td>
                                                                            <td>{row.taxableAmt}</td>
                                                                            <td>{row.gstRate}</td>
                                                                            <td>{row.igstAmt}</td>
                                                                            <td>{row.sgstAmt}</td>
                                                                            <td>{row.cgstAmt}</td>
                                                                            <td>{row.otherCharges}</td>
                                                                            <td>{row.itemTotalAmt}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>

                                                    <table className='mb-3' border="1" width="100%">
                                                        <thead>
                                                            <tr>
                                                                <td><b>Tax'ble Amt</b></td>
                                                                <td><b>CGST Amount</b></td>
                                                                <td><b>SGST Amount</b></td>
                                                                <td><b>IGST Amount</b></td>
                                                                <td><b>CESS Amount</b></td>
                                                                <td><b>State CESS Amount</b></td>
                                                                <td><b>Discount</b></td>
                                                                <td><b>Other Charges</b></td>
                                                                <td><b>Total Invoice Amount</b></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{totalCalculation?.tot_taxable_amt}</td>
                                                                <td>{totalCalculation?.tot_cgst_amt}</td>
                                                                <td>{totalCalculation?.tot_sgst_amt}</td>
                                                                <td>{totalCalculation?.tot_igst_amt}</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>{totalCalculation?.tot_discount_amt}</td>
                                                                <td>{totalCalculation?.tot_othr_charges}</td>
                                                                <td>{totalCalculation?.tot_items_amt}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>

                                            </Col>
                                        </Row>
                                    </div>
                                } />
                    }
                    <Row>
                        <Col xs={12} className='text-end'>
                            <Button className='text-white' color='secondary' onClick={create_json}> Genreate JSON </Button>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter className='text-end'>
                    <Button className='text-white' color='primary' onClick={add_invoice_handler}> Final Submit </Button>
                </ModalFooter>
            </Modal>

        </Page >
    )
}

export default Invoice;