import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';

import Page from '../../component/layout/Page';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import Switch from '@mui/material/Switch';
import { MdDeleteForever } from "react-icons/md";

import { FaEdit, FaSearch } from "react-icons/fa";

import {
    Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Input, Label, Form, Spinner, InputGroup, InputGroupText
} from 'reactstrap';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ProductMaster = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [productModal, setProductModal] = useState(false);
    const [productList, setProductList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [productName, setProductName] = useState('');
    const [gst, setGst] = useState('');
    const [productDesc, setProductDesc] = useState('');

    const [productEditModal, setProductEditModal] = useState(false);
    const [edProductId, setEdProductId] = useState('');
    const [edProductCode, setEdProductCode] = useState('');
    const [edProductName, setEdProductName] = useState('');
    const [edGst, setEdGst] = useState('');
    const [edProductDesc, setEdProductDesc] = useState('');

    useEffect(()=>{
        get_product_list_handler();
    },[]);

    const get_product_list_handler = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data); 
                let list = response.data.map((row, index) => {
                    return  {
                        sl: index + 1,
                        product_no: row.product_code,
                        product_name: row.product_name,
                        gst: row.gst + '%',
                        product_desc: row.detail,
                        action: <>
                            <Switch checked={row.status == 1 ? true : false} onChange={() => change_status_handler(row)} />
                            <FaEdit className='cursor ms-3' color='#fe9300' size={25} onClick={() => open_edit_handler(row)} />
                            <MdDeleteForever className='cursor ms-3' color='#fb3a3b' size={25} onClick={() => delete_handler(row)} />
                        </>
                    }
                })
                setProductList(list); 
                setFilterData(list); 
                setLoading(false);
      
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const product_add_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {"product_code":productCode, "product_name":productName, "desc":productDesc, "gst":gst};

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                    console.log(response.data);
                    setLoading(false);
                    setProductModal(false);
                    let notify = notification({ message: "Product add successfully", type: 'success' });
                    notify();
                    get_product_list_handler();
                    setProductCode('');
                    setProductName('');
                    setProductDesc('');
      
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })  
    }

    const change_status_handler = (row) => {
        let postData;
        if (row.status == 0) {
            postData = { "status": 1, "product_id": row.product_master_id }
        } else {
            postData = { "status": 0, "product_id": row.product_master_id }
        }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_status',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                let notify = notification({ message: "Status changed successfully", type: 'success' });
                notify();
                get_product_list_handler();

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const open_edit_handler = (row) => {
        setEdProductId(row.product_master_id);
        setEdProductCode(row.product_code);
        setEdProductName(row.product_name);
        setEdGst(row.gst);
        setEdProductDesc(row.detail);
        setProductEditModal(true);
    }

    const product_edit_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {
            "product_code":edProductCode, 
            "product_name":edProductName, 
            "gst":edGst, 
            "desc":edProductDesc, 
            "product_id": edProductId
        };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                    setLoading(false);
                    setProductEditModal(false);
                    let notify = notification({ message: "Product update successfully", type: 'success' });
                    notify();
                    get_product_list_handler();
      
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })  
    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete '+  row.product_name +' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "product_id": row.product_master_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/product_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Product deleted successfully", type: 'success' });
                                notify();
                                get_product_list_handler();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });

    }

    const filter_data_handler = (e) => {
        let fData = productList.filter (item => {
            const query = e.target.value.toLowerCase();
            console.log(query)
            return (
                item.product_no.toLowerCase().indexOf(query) >= 0 ||
                item.product_name.toLowerCase().indexOf(query) >= 0 
            )
        })
        setFilterData(fData);

    }
    
    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row className='mb-3'>
                        <Col xs="6">
                            <h5 className='text-primary'>Product Master List</h5>
                        </Col>
                        <Col xs="3" className='text-end'>
                            <Button className='text-white' color='primary' onClick={() => setProductModal(true)}> Add New Product </Button>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs={12}>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "80px"
                                            },
                                            {
                                                name: 'HSN Code',
                                                selector: row => row.product_no,
                                            },
                                            {
                                                name: 'Product Name',
                                                selector: row => row.product_name,
                                                width: '20%',
                                                wrap: true
                                            },
                                            {
                                                name: 'GST',
                                                selector: row => row.gst,
                                            },
                                            {
                                                name: 'Product Description',
                                                selector: row => row.product_desc,
                                                width: '30%',
                                                wrap: true
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                wrap: true
                                            },
                                        ]}
                                        data={filterData}
                                        striped
                                        highlightOnHover
                                        pagination
                                        progressPending={loading}

                                    />

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={productModal}
                toggle={() => setProductModal(!productModal)}
            >
                <ModalHeader toggle={() => setProductModal(!productModal)}>
                    Add New Product
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={product_add_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="productCode"> Product Code* </Label>
                                <Input id="productCode" name="productCode" placeholder="SAC / HSN Code" type="text" value={productCode} onChange={(e) => setProductCode(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productName"> Product Name* </Label>
                                <Input id="productName" name="productName" placeholder="Product Name" type="text" value={productName} onChange={(e) => setProductName(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productName"> GST* </Label>
                                <Input id="productName" name="productName" placeholder="GST" type="number" value={gst} onChange={(e) => setGst(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productDesc"> Description </Label>
                                <Input id="productDesc" name="productDesc" placeholder="Product Description" type="textarea" value={productDesc} onChange={(e) => setProductDesc(e.target.value)} />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <b>Note: </b> (*) Mark field are mandatory.
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={productEditModal}
                toggle={() => setProductEditModal(!productEditModal)}
            >
                <ModalHeader toggle={() => setProductEditModal(!productEditModal)}>
                    Edit Product
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={product_edit_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="productCode"> Product Code </Label>
                                <Input id="productCode" name="productCode" placeholder="HSN Code" type="text" value={edProductCode} onChange={(e) => setEdProductCode(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productName"> Product Name </Label>
                                <Input id="productName" name="productName" placeholder="Product Name" type="text" value={edProductName} onChange={(e) => setEdProductName(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productName"> GST </Label>
                                <Input id="productName" name="productName" placeholder="GST" type="number" value={edGst} onChange={(e) => setEdGst(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="productDesc"> Description </Label>
                                <Input id="productDesc" name="productDesc" placeholder="Product Description" type="textarea" value={edProductDesc} onChange={(e) => setEdProductDesc(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > Update {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Page >
    )
}

export default ProductMaster;