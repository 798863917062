export const getColor = (availableColor = 'primary') => {
    if (typeof window === 'undefined') {
      return null;
    }
  
    const color = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--${availableColor}`);
  
    return color;
  };
  
  export const getThemeColors = () => [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  export const custTableStyles = {
    headRow: {
      style: {
        borderRadius: '0px',
        minHeight: '40px', // override the row height
        //maxHeight: '40px',
        background: "rgba(254, 163, 38, 0.8)",
      }
    },
    rows: {
        style: {
            minHeight: '40px', // override the row height
            //maxHeight: '40px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            minHeight: '40px',
            //maxHeight: '40px',
        },
    },
    cells: {
        style: {
            paddingLeft: '5px', // override the cell padding for data cells
            paddingRight: '5px',
        },
    },
  };
  