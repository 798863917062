import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import PdfGenerator from '../../component/utility/PdfGenerator';
import { Row, Col, Card, CardBody, Label, Input, Form, Button, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select'

const CustomerAllReport = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [companyOpt, setCompanyOpt] = useState('');
    const [custOpt, setCustOpt] = useState('');
    const [productOpt, setProductOpt] = useState('');

    const [company, setCompany] = useState('');
    const [customer, setCustomer] = useState('');
    const [product, setProduct] = useState('');
    const [dateFrm, setDateFrm] = useState(moment().subtract(60, "days").format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

    const [purchaseData, setPurchaseData] = useState('');
    const [saleData, setSaleData] = useState('');
    const [paymentData, setPaymentData] = useState('');
    const [invoiceData, setInvoiceData] = useState('');
    // pdf
    const [pdfModal, setPdfModal] = useState('');

    useEffect(() => {
        get_init();
        //
    }, []);

    const get_init = () => {
        let all =  { value: 0, label: "All" };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return { value: row.company_code, label: row.name }
                });
                list.unshift(all);
                setCompanyOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.customer_id, label: row.name, gst: row.gst_no }
                });
                setCustOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })

        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.product_master_id, label: row.product_name} 

                });
                list.unshift(all);
                setProductOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        // 
    }


    const submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setPurchaseData('');
        setSaleData('');
        setPaymentData('');
        setInvoiceData('');

        let postData = {
            'company_code': company.value,
            'customer_id': customer.value,
            'product_code': product.value,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/customer_all_report',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //Purchase 
                if (response.data.purches !== null) {
                    let total_purchase = 0;
                    let total_gst = 0;
                    let total_inv = 0;
                    let total_weight = 0;
                    let total_cash_discount = 0;
                    let total_claim_amount = 0;

                    let purchase_list = response.data.purches.map((row, index) => {
                        total_purchase = (Number(total_purchase) + Number(row.total_price));
                        total_cash_discount = (Number(total_cash_discount) + Number(row.cash_discount));
                        total_claim_amount = (Number(total_claim_amount) + Number(row.claim_amount))
                        total_gst = (Number(total_gst) + Number(row.invoice_gst_amount));
                        total_inv = (Number(total_inv) + Number(row.invoice_value));

                        let wgt = (Number(row.bags) * Number(row.weight));
                        if (row.unit == '1') {
                            let temp = Number(wgt / 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '2') {
                            let temp = Number(wgt).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '3') {
                            let temp = Number(wgt * 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        }

                        return {
                            sl: index + 1,
                            broker: row.broker_name,
                            customer: row.customer_name,
                            company: row.company_code === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            p_date: row.purchase_date?.split(" ")[0].split('-').reverse().join('-'),
                            product: row.product_name + ' (' + row.product_code + ')',
                            weight: wgt + ' ' + (row.unit == '1' ? 'Gm' : row.unit == '2' ? 'Kg' : 'Qtl'),
                            amount: row.total_price,
                            cash_discount: row.cash_discount,
                            claim_amt: row.claim_amount,
                            tot_amt: Number(Number(row.total_price) - Number(row.cash_discount) - Number(row.claim_amount) + Number(row.invoice_gst_amount)).toFixed(2),
                            gst_amt: row.invoice_gst_amount,
                            inv_amt: row.invoice_value,
                           // tot_amt: Number(Number(row.total_price) + Number(row.invoice_gst_amount)).toFixed(2),
                        }
                    });
                    let p_temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        p_date: '',
                        product: <b>Total: </b>,
                        weight: <b>{Number(total_weight).toFixed(2)} Kg</b>,
                        amount: <b>{Number(total_purchase).toFixed(2)}</b>,
                        cash_discount: <b>{Number(total_cash_discount).toFixed(2)}</b>,
                        claim_amt: <b>{Number(total_claim_amount).toFixed(2)}</b>,
                        tot_amt: <b>{Number(total_purchase - total_cash_discount - total_claim_amount + Number(total_gst)).toFixed(2)}</b>,
                        gst_amt: <b>{Number(total_gst).toFixed(2)}</b>,
                        inv_amt: <b>{Number(total_inv).toFixed(2)}</b>,
                    }
                    purchase_list.push(p_temp);
                    //
                    setPurchaseData(purchase_list);
                }
                //Sale 
                if (response.data.sale !== null) {
                    let total = 0;
                    let total_gst = 0;
                    let total_inv = 0;
                    let total_weight = 0;
                    let total_cash_discount = 0;
                    let total_claim_amount = 0;

                    let list = response.data.sale.map((row, index) => {

                        total = (Number(total) + Number(row.total_price));
                        total_cash_discount = (Number(total_cash_discount) + Number(row.cash_discount));
                        total_claim_amount = (Number(total_claim_amount) + Number(row.claim_amount))
                        total_gst = (Number(total_gst) + Number(row.invoice_gst_amount));
                        total_inv = (Number(total_inv) + Number(row.invoice_value));
                        let wgt = (Number(row.bags) * Number(row.weight));
                        if (row.unit == '1') {
                            let temp = Number(wgt / 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '2') {
                            let temp = Number(wgt).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '3') {
                            let temp = Number(wgt * 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        }

                        return {
                            sl: index + 1,
                            customer: row.customer_name,
                            broker: row.broker_name,
                            company: row.company_code === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            p_date: row.purchase_date?.split(" ")[0].split('-').reverse().join('-'),
                            product: row.product_name + ' (' + row.product_code + ')',
                            weight: wgt + ' ' + (row.unit == '1' ? 'Gm' : row.unit == '2' ? 'Kg' : 'Qtl'),
                            amount: row.total_price,
                            cash_discount: row.cash_discount,
                            claim_amt: row.claim_amount,
                            tot_amt: Number(Number(row.total_price) - Number(row.cash_discount) - Number(row.claim_amount) + Number(row.invoice_gst_amount)).toFixed(2),
                            gst_amt: row.invoice_gst_amount,
                            inv_amt: row.invoice_value,

                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        p_date: '',
                        product: <b>Total: </b>,
                        weight: <b>{Number(total_weight).toFixed(2)} Kg</b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                        cash_discount: <b>{Number(total_cash_discount).toFixed(2)}</b>,
                        claim_amt: <b>{Number(total_claim_amount).toFixed(2)}</b>,
                        tot_amt: <b>{Number(total - total_cash_discount - total_claim_amount + total_gst).toFixed(2)}</b>,
                        gst_amt: <b>{Number(total_gst).toFixed(2)}</b>,
                        inv_amt: <b>{Number(total_inv).toFixed(2)}</b>,

                    }
                    list.push(temp);
                    //
                    setSaleData(list);
                }
                //Payment 
                if (response.data.payment !== null) {
                    let total = 0;

                    let list = response.data.payment.map((row, index) => {

                        if(row.trns_type == "1"){
                            total = (Number(total) + Number(row.amount));
                        }else{
                            total = (Number(total) - Number(row.amount));
                        }
                        return {
                            sl: index + 1,
                            customer: row.customer_name,
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            trns_type: row.trns_type === "1" ? "Credit" : "Debit",
                            payment_for: row.payment_for,
                            payment_type: row.payment_type === "1" ? "On-Line" : "Cash",
                            amount: 'Rs. ' + Number(row.amount).toFixed(2),
                            p_date: row.date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        trns_type: '',
                        p_date: '',
                        payment_for: <b>Total: </b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                    }
                    list.push(temp);
                    //
                    setPaymentData(list);
                }
                //Invoice 
                if (response.data.invoice !== null) {
                    let total = 0;

                    let list = response.data.invoice.map((row, index) => {

                        total = (Number(total) + Number(row.total_amount));

                        return {
                            sl: index + 1,
                            doc_no: row.doc_no,
                            type: row.doc_type === 'INV' ? "Tax Invoice" : row.doc_type === 'CRN' ? "Credit Note" : row.doc_type === 'DBN' ? "Debit Note" : "Bill of supply",
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            category: row.category,
                            amount: row.total_amount,
                            p_date: row.doc_date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        product: <b>Total: </b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                        p_date: '',
                    }
                    list.push(temp);
                    //
                    setInvoiceData(list);
                }



                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Customer All Report</h5>
                        </Col>
                    </Row>

                    <Card className='mb-3'>
                        <CardBody>
                            <Form onSubmit={submit_handler}>
                                <Row>
                                    <Col xs={3} className='mb-2'>
                                        <Label> Customer Name*</Label>
                                        <Select options={custOpt} onChange={opt => setCustomer(opt)} placeholder="Select Customer" required />
                                    </Col>
                                    <Col xs={3} className='mb-2'>
                                        <Label> Company*</Label>
                                        <Select options={companyOpt} onChange={opt => setCompany(opt)} placeholder="Select Company" required />
                                    </Col>
                                    <Col xs={3} className='mb-2'>
                                        <Label> Product*</Label>
                                        <Select options={productOpt} onChange={opt => setProduct(opt)} placeholder="Select Product" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label> Date From*</Label>
                                        <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label> Date To*</Label>
                                        <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Button className='text-white mt-4' color='primary'> Find </Button>
                                        <Button className='text-white mt-4 ms-4' color='secondary' onClick={() => setPdfModal(true)} > PDF </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col md={12} className='mb-3 h-100'>
                            <Card className='h-100'>
                                <CardHeader><b>Purchase Report</b></CardHeader>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                                wrap: true
                                            },
                                            {
                                                name: 'Broker Name',
                                                selector: row => row.broker,
                                                wrap: true
                                            },
                                            {
                                                name: 'Purchase Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product',
                                                selector: row => row.product,
                                                wrap: true,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Weight',
                                                selector: row => row.weight,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Cash Discount',
                                                selector: row => row.cash_discount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Claim Amt',
                                                selector: row => row.claim_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Total Amt',
                                                selector: row => row.tot_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'GST Amt',
                                                selector: row => row.gst_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Amt',
                                                selector: row => row.inv_amt,
                                                wrap: true
                                            },

                                        ]}
                                        data={purchaseData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12} className='mb-3 h-100'>
                            <Card className='h-100'>
                                <CardHeader><b>Sale Report</b></CardHeader>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                                wrap: true
                                            },
                                            {
                                                name: 'Broker Name',
                                                selector: row => row.broker,
                                                wrap: true
                                            },
                                            {
                                                name: 'Sale Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product',
                                                selector: row => row.product,
                                                wrap: true,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Weight',
                                                selector: row => row.weight,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Cash Discount',
                                                selector: row => row.cash_discount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Claim Amt',
                                                selector: row => row.claim_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Total Amt',
                                                selector: row => row.tot_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'GST Amt',
                                                selector: row => row.gst_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Amt',
                                                selector: row => row.inv_amt,
                                                wrap: true
                                            },

                                        ]}
                                        data={saleData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12} className='mb-3 h-100'>
                            <Card className='h-100'>
                                <CardHeader><b>Payment Report</b></CardHeader>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Transaction Type',
                                                selector: row => row.trns_type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment Type',
                                                selector: row => row.payment_type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment For',
                                                selector: row => row.payment_for,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                        ]}
                                        data={paymentData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12} className='mb-3 h-100'>
                            <Card className='h-100'>
                                <CardHeader><b>Invoice Report</b></CardHeader>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                                wrap: true
                                            },
                                            {
                                                name: 'Doc No.',
                                                selector: row => row.doc_no,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Type',
                                                selector: row => row.type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Category',
                                                selector: row => row.category,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },

                                        ]}
                                        data={invoiceData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Modal
                centered
                scrollable
                size="xl"
                isOpen={pdfModal}
                toggle={() => setPdfModal(!pdfModal)}
            >
                <ModalHeader toggle={() => setPdfModal(!pdfModal)}>
                    PDF
                </ModalHeader>
                <ModalBody className='' >
                    <PdfGenerator content={
                        <>
                            <Row>
                                <Col xs="6" className='mb-3'>
                                    <Row>
                                        <Col xs="6">
                                            <b>Customer Name : </b>
                                        </Col>
                                        <Col xs="6">
                                            <b> {customer.label} </b>
                                        </Col>
                                        <Col xs="6">
                                            <b>Company Name : </b>
                                        </Col>
                                        <Col xs="6">
                                            <b> {company.label} </b>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="6" className='mb-3'>
                                    <Row>
                                        <Col xs="6">
                                            <b>From Date : </b>
                                        </Col>
                                        <Col xs="6">
                                            <b> {dateFrm?.split('-').reverse().join('-')} </b>
                                        </Col>
                                        <Col xs="6">
                                            <b>To Date : </b>
                                        </Col>
                                        <Col xs="6">
                                            <b> {dateTo?.split('-').reverse().join('-')} </b>
                                        </Col>
                                    </Row>
                                </Col>

                                <hr/>

                                <Col xs="12" className='mb-3'>
                                    <h5>Purchase Report</h5>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Broker Name',
                                                selector: row => row.broker,
                                                wrap: true
                                            },
                                            {
                                                name: 'Purchase Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product',
                                                selector: row => row.product,
                                                wrap: true,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Weight',
                                                selector: row => row.weight,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Cash Discount',
                                                selector: row => row.cash_discount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Claim Amt',
                                                selector: row => row.claim_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Total Amt',
                                                selector: row => row.tot_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'GST Amt',
                                                selector: row => row.gst_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Amt',
                                                selector: row => row.inv_amt,
                                                wrap: true
                                            },

                                        ]}
                                        data={purchaseData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>    
                                <hr/>

                                <Col xs="12" className='mb-3'>
                                    <h5>Sale Report</h5>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Sale Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Broker Name',
                                                selector: row => row.broker,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product',
                                                selector: row => row.product,
                                                wrap: true,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Weight',
                                                selector: row => row.weight,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Cash Discount',
                                                selector: row => row.cash_discount,
                                                wrap: true
                                            },
                                            {
                                                name: 'Claim Amt',
                                                selector: row => row.claim_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Total Amt',
                                                selector: row => row.tot_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'GST Amt',
                                                selector: row => row.gst_amt,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Amt',
                                                selector: row => row.inv_amt,
                                                wrap: true
                                            },

                                        ]}
                                        data={saleData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>    
                                <hr/>

                                <Col xs="12" className='mb-3'>
                                    <h5>Payment Report</h5>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Payment Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Transaction Type',
                                                selector: row => row.trns_type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment Type',
                                                selector: row => row.payment_type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment For',
                                                selector: row => row.payment_for,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },
                                        ]}
                                        data={paymentData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>    
                                <hr/>
                                <Col xs="12" className='mb-3'>
                                    <h5>Invoice Report</h5>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Doc No.',
                                                selector: row => row.doc_no,
                                                wrap: true
                                            },
                                            {
                                                name: 'Invoice Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Type',
                                                selector: row => row.type,
                                                wrap: true
                                            },
                                            {
                                                name: 'Category',
                                                selector: row => row.category,
                                                wrap: true
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                                wrap: true
                                            },

                                        ]}
                                        data={invoiceData}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>    
                                <hr/>    
                            </Row>
                        </>
                    } />
                </ModalBody>
            </Modal>
        </Page >
    )
}

export default CustomerAllReport;