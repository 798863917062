import React from 'react';
import Header from './Header';

const Page = ({ children, ...props}) => {
    return (
        <div className='p-3' style={{marginTop:"65px"}}>
            <Header />
            <div className='pageContainer'>
                {children}
            </div>
        </div>
    )
}
export default Page;