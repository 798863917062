import React, { useEffect, useState } from 'react';

import { Row, Col, } from 'reactstrap';
import Page from '../component/layout/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from './../style/color';
import axios from 'axios';
import { notification } from '../component/hocs/notification';
import mainUrl from '../MainUrl';

import { FaPeopleGroup, FaMoneyBillTransfer  } from "react-icons/fa6";
import { GiGrainBundle, GiTakeMyMoney, GiReceiveMoney} from 'react-icons/gi';
import { TbTruckDelivery } from "react-icons/tb";

const Home = () => {

    const [loading, setLoading] = useState('');
    const [stockList, setStockList] = useState('');
    const [dbData, setDbData] = useState('');


    useEffect(() => {
        window.scrollTo(0, 0);
        init_handler();
    }, [])

    const init_handler = () => {
        // Dashboard Status Data
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/dashbord_data',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                
                setDbData(response.data[0]);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (
        <Page>
            <Row>
                <Col md="12" className="text-center text-primary mt-2 mb-2">
                    <h3>Welcome to Stock Management System</h3>
                </Col>
                <hr />
                <Col md="12" className="text-center mt-2 mb-2">
                    <Row>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-secondary">
                                        <h6 className='mt-3'> Total Customer</h6>
                                        <h3>{dbData.total_customer}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <FaPeopleGroup size={80} color="#fe9300" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-primary">
                                        <h6 className='mt-3'> Total Product</h6>
                                        <h3>{dbData.total_product}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <GiGrainBundle size={80} color="#0188c7" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-secondary">
                                        <h6 className='mt-3'> Total Transporter</h6>
                                        <h3>{dbData.total_transpoter}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <TbTruckDelivery size={80} color="#fe9300" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-primary">
                                        <h6 className='mt-3'> Total Transaction</h6>
                                        <h3>{dbData.total_trns}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <FaMoneyBillTransfer size={80} color="#0188c7" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-secondary">
                                        <h6 className='mt-3'> Total Purchase</h6>
                                        <h3>{dbData.total_parches}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <GiTakeMyMoney size={80} color="#fe9300" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4} className="mb-5">
                            <div className='iconWidget'>
                                <Row>
                                    <Col xs={7} className="text-center text-primary">
                                        <h6 className='mt-3'> Total Sale</h6>
                                        <h3>{dbData.total_sell}</h3>
                                    </Col>
                                    <Col xs={5} className="text-center">
                                        <GiReceiveMoney size={80} color="#0188c7" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
                

            </Row>
        </Page>
    )
}

export default Home;