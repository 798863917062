import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import { Row, Col, Card, CardBody, Label, Input, Form, Button, CardHeader } from 'reactstrap';
import Select from 'react-select'

const TransportReport = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [transportOpt, setTransportOpt] = useState('');
    const [transport, setTransport] = useState('');
    const [dateFrm, setDateFrm] = useState(moment().subtract(60, "days").format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

    const [tableData, setTableData] = useState('');
    const [tableData2, setTableData2] = useState('');

    useEffect(() => {
        get_init();
        //
    }, []);

    const get_init = () => {

        //  
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_5 = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.transport_master_id, label: row.transport_master_name }
                });
                setTransportOpt(list_5);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })

    }


    const submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTableData('');
        setTableData2('');

        let postData = {
            'trns_id': transport,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/trns_report',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data !== null) {

                    let t_amt = 0, ad_amt = 0, c_amt = 0;

                    let list = response.data.map((row, index) => {
                        t_amt = Number(t_amt) + Number(row.transport_amt);
                        ad_amt = Number(ad_amt) + Number(row.advance_payment);
                        c_amt = Number(c_amt) + Number(row.complete_payment);

                        return {
                            sl: index + 1,
                            trans_name: row.transport_master_name,
                            vahicle_no: row.vahicle_no,
                            transport_chrg: row.transport_chrg,
                            gst_amt: row.gst_amt,
                            transport_amt: row.transport_amt,
                            advance_payment: row.advance_payment,
                            complete_payment: row.complete_payment,
                        }
                    });
                    //
                    let temp = {
                        sl: '',
                        trans_name: '',
                        vahicle_no: '',
                        transport_chrg: '',
                        gst_amt: <b>Total</b>,
                        transport_amt: <b>{t_amt}</b>,
                        advance_payment: <b>{ad_amt}</b>,
                        complete_payment: <b>{c_amt}</b>,
                    }
                    list.push(temp);
                    //
                    setTableData(list);
                    //
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        // payment
        let postData2 = {
            'company_code': 0,
            'transpoter_id': transport,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_report_transpoter',
            data: postData2,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let total = 0;
                if (response.data !== null) {

                    let list = response.data.map((row, index) => {

                        if(row.trns_type == "1"){
                            total = (Number(total) + Number(row.amount));
                        }else{
                            total = (Number(total) - Number(row.amount));
                        }


                        return {
                            sl: index + 1,
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            trns_type: row.trns_type === "1" ? "Credit" : "Debit",
                            payment_for: row.payment_for,
                            payment_type: row.payment_type === "1" ? "On-Line" : "Cash",
                            amount: row.amount,
                            p_date: row.date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        trns_type: '',
                        payment_for: <b>Total Amount</b>,
                        amount: <b>{Number(total)}</b>,
                        p_date: '',
                    }
                    list.push(temp);
                    //
                    setTableData2(list);
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Transport Report</h5>
                        </Col>
                    </Row>
                    <Card className='mb-2'>
                        <CardBody>
                            <Form onSubmit={submit_handler}>
                                <Row>
                                    <Col xs={3} className='mb-2'>
                                        <Label for="TransportName"> Transport Name </Label>
                                        <Select options={transportOpt} onChange={opt => setTransport(opt.value)} placeholder="Select Transport" />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date From*</Label>
                                        <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date To*</Label>
                                        <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Button className='text-white mt-4' color='primary'> Find </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>Transaction</CardHeader>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: "60px"
                                    },
                                    {
                                        name: 'Transport Name',
                                        selector: row => row.trans_name,
                                        wrap: true
                                    },
                                    {
                                        name: 'Vahicle No',
                                        selector: row => row.vahicle_no,
                                        wrap: true,
                                    },
                                    {
                                        name: 'Transport Charge',
                                        selector: row => row.transport_chrg,
                                        wrap: true,
                                    },
                                    {
                                        name: 'GST Amount',
                                        selector: row => row.gst_amt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Amount',
                                        selector: row => row.transport_amt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Advance Payment',
                                        selector: row => row.advance_payment,
                                        wrap: true
                                    },
                                    {
                                        name: 'Complete Payment',
                                        selector: row => row.complete_payment,
                                        wrap: true
                                    },

                                ]}
                                data={tableData}
                                striped
                                highlightOnHover
                                pagination
                                progressPending={loading}
                            />

                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>Payment</CardHeader>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: "60px"
                                    },
                                    {
                                        name: 'Payment Date',
                                        selector: row => row.p_date,
                                        wrap: true
                                    },
                                    {
                                        name: 'Company',
                                        selector: row => row.company,
                                    },
                                    {
                                        name: 'Transaction Type',
                                        selector: row => row.trns_type,
                                    },
                                    {
                                        name: 'Payment For',
                                        selector: row => row.payment_for,
                                    },
                                    {
                                        name: 'Payment Type',
                                        selector: row => row.payment_type,
                                    },
                                    {
                                        name: 'Amount',
                                        selector: row => row.amount,
                                    },

                                ]}
                                data={tableData2}
                                striped
                                highlightOnHover
                                pagination
                                progressPending={loading}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default TransportReport;