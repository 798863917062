import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import { Row, Col, Card, CardBody, Label, Input, Form, Button } from 'reactstrap';
import Select from 'react-select'

const InvoiceReport = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [companyOpt, setCompanyOpt] = useState('');
    const [custOpt, setCustOpt] = useState('');
    const [statusOpt, setStatusOpt] = useState('');

    const [company, setCompany] = useState('');
    const [customer, setCustomer] = useState('');
    const [status, setStatus] = useState('');
    const [dateFrm, setDateFrm] = useState(moment().subtract(60, "days").format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

    const [tableData, setTableData] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(() => {
        get_init();
        //
    }, []);

    const get_init = () => {
        let all = { value: 0, label: "All" };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return { value: row.company_code, label: row.name }
                });
                list.unshift(all);
                setCompanyOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.customer_id, label: row.name, gst: row.gst_no }
                });
                setCustOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        
        let status_opt = [
            {value: 1, label: 'Accepted'},
            {value: 2, label: 'Rejected'},
        ];
        setStatusOpt(status_opt);

    }


    const submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTableData('');

        let postData = {
            'company_code': company.value,
            'customer_id': customer,
            'status': status,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/invoice_report',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let total = 0;
                if(response.data !== null){

                    let list = response.data.map((row, index) => {
    
                        total = ( Number(total) + Number(row.total_amount));
    
                        return {
                            sl: index + 1,
                            type: row.doc_type === 'INV' ? "Tax Invoice" : row.doc_type === 'CRN' ? "Credit Note" : row.doc_type === 'DBN' ? "Debit Note" : "Bill of supply",
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            category: row.category,
                            amount: row.total_amount,
                            p_date: row.doc_date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        product: <b>Total Amount</b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                        p_date: '',
                    }
                    list.push(temp);
                    //
                    setTableData(list);
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Invoice Report</h5>
                        </Col>
                    </Row>
                    <Card className='mb-2'>
                        <CardBody>
                            <Form onSubmit={submit_handler}>
                                <Row>
                                    <Col xs={3} className='mb-2'>
                                        <Label for="purchase_from"> Company*</Label>
                                        <Select options={companyOpt} onChange={opt => setCompany(opt)} placeholder="Select Company" required />
                                    </Col>
                                    <Col xs={3} className='mb-2'>
                                        <Label for="purchase_from"> Customer Name*</Label>
                                        <Select options={custOpt} onChange={opt => setCustomer(opt.value)} placeholder="Select Customer" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Status* </Label>
                                        <Select options={statusOpt} onChange={opt => setStatus(opt.value)} placeholder="Select Status" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date From*</Label>
                                        <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date To*</Label>
                                        <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Button className='text-white mt-4' color='primary'> Find </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: "60px"
                                    },
                                    {
                                        name: 'Invoice Date',
                                        selector: row => row.p_date,
                                        wrap: true
                                    },
                                    {
                                        name: 'Type',
                                        selector: row => row.type,
                                    },
                                    {
                                        name: 'Company',
                                        selector: row => row.company,
                                    },
                                    {
                                        name: 'Category',
                                        selector: row => row.category,
                                    },
                                    {
                                        name: 'Amount',
                                        selector: row => row.amount,
                                    },

                                ]}
                                data={tableData}
                                striped
                                highlightOnHover
                                progressPending={loading}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default InvoiceReport;