import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import { Row, Col, Card, CardBody, Label, Input, Form, Button } from 'reactstrap';
import Select from 'react-select'

const PurchaseReport = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [companyOpt, setCompanyOpt] = useState('');
    const [productOpt, setProductOpt] = useState('');
    const [custOpt, setCustOpt] = useState('');

    const [company, setCompany] = useState('');
    const [product, setProduct] = useState('');
    const [customer, setCustomer] = useState('');
    const [dateFrm, setDateFrm] = useState(moment().subtract(60, "days").format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

    const [tableData, setTableData] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(() => {
        get_init();
        //
    }, []);

    const get_init = () => {
        let all = { value: 0, label: "All" };
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return { value: row.company_code, label: row.name }
                });
                list.unshift(all);
                setCompanyOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.customer_id, label: row.name, gst: row.gst_no }
                });
                setCustOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })

        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/product_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.product_master_id, label: row.product_name }

                });
                list.unshift(all);
                setProductOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        // 
    }


    const submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTableData('');

        let postData = {
            'company_code': company.value,
            'product_code': product.value,
            'customer_id': customer,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/purches_reports',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data !== null) {

                    let total_purchase = 0;
                    let total_gst = 0;
                    let total_inv = 0;
                    let total_weight = 0;
                    let total_cash_discount = 0;
                    let total_claim_amount = 0;

                    let purchase_list = response.data.map((row, index) => {
                        total_purchase = (Number(total_purchase) + Number(row.total_price));
                        total_cash_discount = (Number(total_cash_discount) + Number(row.cash_discount));
                        total_claim_amount = (Number(total_claim_amount) + Number(row.claim_amount))
                        total_gst = (Number(total_gst) + Number(row.invoice_gst_amount));
                        total_inv = (Number(total_inv) + Number(row.invoice_value));

                        let wgt = (Number(row.bags) * Number(row.weight));
                        if (row.unit == '1') {
                            let temp = Number(wgt / 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '2') {
                            let temp = Number(wgt).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        } else if (row.unit == '3') {
                            let temp = Number(wgt * 1000).toFixed(2);
                            total_weight = (Number(total_weight) + Number(temp));
                        }

                        return {
                            sl: index + 1,
                            customer: row.customer_name,
                            company: row.company_code === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            p_date: row.purchase_date?.split(" ")[0].split('-').reverse().join('-'),
                            product: row.product_name + ' (' + row.product_code + ')',
                            weight: wgt + ' ' + (row.unit == '1' ? 'Gm' : row.unit == '2' ? 'Kg' : 'Qtl'),
                            amount: row.total_price,
                            cash_discount: row.cash_discount,
                            claim_amt: row.claim_amount,
                            tot_amt: Number(Number(row.total_price) - Number(row.cash_discount) - Number(row.claim_amount)).toFixed(2),
                            gst_amt: row.invoice_gst_amount,
                            inv_amt: row.invoice_value,
                            // tot_amt: Number(Number(row.total_price) + Number(row.invoice_gst_amount)).toFixed(2),
                        }
                    });
                    let p_temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        p_date: '',
                        product: <b>Total: </b>,
                        weight: <b>{Number(total_weight).toFixed(2)} Kg</b>,
                        amount: <b>{Number(total_purchase).toFixed(2)}</b>,
                        cash_discount: <b>{Number(total_cash_discount).toFixed(2)}</b>,
                        claim_amt: <b>{Number(total_claim_amount).toFixed(2)}</b>,
                        tot_amt: <b>{Number(total_purchase - total_cash_discount - total_claim_amount).toFixed(2)}</b>,
                        gst_amt: <b>{Number(total_gst).toFixed(2)}</b>,
                        inv_amt: <b>{Number(total_inv).toFixed(2)}</b>,
                    }
                    purchase_list.push(p_temp);
                    //
                    setTableData(purchase_list);
                    //
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Purchase Report</h5>
                        </Col>
                    </Row>
                    <Card className='mb-2'>
                        <CardBody>
                            <Form onSubmit={submit_handler}>
                                <Row>
                                    <Col xs={3} className='mb-2'>
                                        <Label for="purchase_from"> Company*</Label>
                                        <Select options={companyOpt} onChange={opt => setCompany(opt)} placeholder="Select Company" required />
                                    </Col>
                                    <Col xs={3} className='mb-2'>
                                        <Label> Product*</Label>
                                        <Select options={productOpt} onChange={opt => setProduct(opt)} placeholder="Select Product" required />
                                    </Col>
                                    <Col xs={3} className='mb-2'>
                                        <Label for="purchase_from"> Purchase From (Customer Name)*</Label>
                                        <Select options={custOpt} onChange={opt => setCustomer(opt.value)} placeholder="Select Customer" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date From*</Label>
                                        <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Label for="purchase_from"> Date To*</Label>
                                        <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                    </Col>
                                    <Col xs={2} className='mb-2'>
                                        <Button className='text-white mt-4' color='primary'> Find </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DataTable
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                        name: 'Sl.no',
                                        selector: row => row.sl,
                                        width: "60px"
                                    },
                                    {
                                        name: 'Purchase Date',
                                        selector: row => row.p_date,
                                        wrap: true
                                    },
                                    {
                                        name: 'Company',
                                        selector: row => row.company,
                                        wrap: true,
                                    },
                                    {
                                        name: 'Product',
                                        selector: row => row.product,
                                        wrap: true,
                                        width: '20%'
                                    },
                                    {
                                        name: 'Weight',
                                        selector: row => row.weight,
                                        wrap: true
                                    },
                                    {
                                        name: 'Amount',
                                        selector: row => row.amount,
                                        wrap: true
                                    },
                                    {
                                        name: 'Cash Discount',
                                        selector: row => row.cash_discount,
                                        wrap: true
                                    },
                                    {
                                        name: 'Claim Amt',
                                        selector: row => row.claim_amt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Total Amt',
                                        selector: row => row.tot_amt,
                                        wrap: true
                                    },
                                    {
                                        name: 'GST Amt',
                                        selector: row => row.gst_amt,
                                        wrap: true
                                    },
                                    {
                                        name: 'Invoice Amt',
                                        selector: row => row.inv_amt,
                                        wrap: true
                                    },

                                ]}
                                data={tableData}
                                striped
                                highlightOnHover
                                progressPending={loading}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default PurchaseReport;