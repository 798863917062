import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import { Row, Col, Card, CardBody, Label, Input, Form, Button, CardHeader } from 'reactstrap';
import Select from 'react-select'

const PaymentReport = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);

    const [companyOpt, setCompanyOpt] = useState('');
    const [custOpt, setCustOpt] = useState('');
    const [transOpt, setTransOpt] = useState('');

    const [company, setCompany] = useState('');
    const [customer, setCustomer] = useState('');
    const [transpoter, setTranspoter] = useState('');
    const [dateFrm, setDateFrm] = useState(moment().subtract(60, "days").format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

    const [tableData1, setTableData1] = useState('');
    const [tableData2, setTableData2] = useState('');

    useEffect(() => {
        get_init();
        //
    }, []);

    const get_init = () => {
        let all = { value: 0, label: "All" };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return { value: row.company_code, label: row.name }
                });
                list.unshift(all);
                setCompanyOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.customer_id, label: row.name, gst: row.gst_no }
                });
                setCustOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el) => el.status == 1).map((row, index) => {
                    return { value: row.transport_master_id, label: row.transport_master_name, dtl: row }
                });
                setTransOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })

    }


    const customer_submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTableData1('');

        let postData = {
            'company_code': company.value,
            'customer_id': customer.value,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_report_customer',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let total = 0;
                if(response.data !== null){

                    let list = response.data.map((row, index) => {
    
                        total = ( Number(total) + Number(row.amount));
    
                        return {
                            sl: index + 1,
                            customer: customer.label,
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            trns_type: row.trns_type === "1" ? "Credit" : "Debit",
                            payment_for: row.payment_for,
                            payment_type: row.payment_type === "1" ? "On-Line" : "Cash",
                            amount: row.amount,
                            p_date: row.date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        trns_type: '',
                        payment_for: <b>Total Amount</b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                        p_date: '',
                    }
                    list.push(temp);
                    //
                    setTableData1(list);
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    const transpoter_submit_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTableData2('');

        let postData = {
            'company_code': company.value,
            'transpoter_id': transpoter.value,
            'from_date': dateFrm,
            'to_date': dateTo
        }
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_report_transpoter',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let total = 0;
                if(response.data !== null){

                    let list = response.data.map((row, index) => {
    
                        total = ( Number(total) + Number(row.amount));
    
                        return {
                            sl: index + 1,
                            customer: transpoter.label,
                            company: row.company_id === "101" ? "Bubu Trading Co." : "Moumi Trading Co.",
                            trns_type: row.trns_type === "1" ? "Credit" : "Debit",
                            payment_for: row.payment_for,
                            payment_type: row.payment_type === "1" ? "On-Line" : "Cash",
                            amount: row.amount,
                            p_date: row.date?.split('-').reverse().join('-'),
                        }
                    });
                    let temp = {
                        sl: '',
                        customer: '',
                        company: '',
                        trns_type: '',
                        payment_for: <b>Total Amount</b>,
                        amount: <b>{Number(total).toFixed(2)}</b>,
                        p_date: '',
                    }
                    list.push(temp);
                    //
                    setTableData2(list);
                }
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Payment Report</h5>
                        </Col>
                    </Row>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Tabs value={value} onChange={handleChange} centered indicatorColor="secondary"
          textColor="inherit">
                            <Tab label="Customer" />
                            <Tab label="Transpoter" />
                        </Tabs>
                    </Box>

                    { value == 0 &&
                        <div>
                            
                            <Card className='mb-2'>
                                <CardHeader>Customer Payment Report</CardHeader>
                                <CardBody>
                                    <Form onSubmit={customer_submit_handler}>
                                        <Row>
                                            <Col xs={3} className='mb-2'>
                                                <Label for="purchase_from"> Company*</Label>
                                                <Select options={companyOpt} onChange={opt => setCompany(opt)} placeholder="Select Company" required />
                                            </Col>
                                            <Col xs={3} className='mb-2'>
                                                <Label for="purchase_from"> Customer Name*</Label>
                                                <Select options={custOpt} onChange={opt => setCustomer(opt)} placeholder="Select Customer" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Label for="purchase_from"> Date From*</Label>
                                                <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Label for="purchase_from"> Date To*</Label>
                                                <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Button className='text-white mt-4' color='primary'> Find </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Payment Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Customer Name',
                                                selector: row => row.customer,
                                                wrap: true
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                                wrap: true
                                            },
                                            {
                                                name: 'Transaction Type',
                                                selector: row => row.trns_type,
                                            },
                                            {
                                                name: 'Payment For',
                                                selector: row => row.payment_for,
                                                wrap: true
                                            },
                                            {
                                                name: 'Payment Type',
                                                selector: row => row.payment_type,
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                            },

                                        ]}
                                        data={tableData1}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                </CardBody>
                            </Card>
                        </div>
                    }
                    { value == 1 &&
                        <div>

                            <Card className='mb-2'>
                                <CardHeader>Transpoter Payment Report</CardHeader>
                                <CardBody>
                                    <Form onSubmit={transpoter_submit_handler}>
                                        <Row>
                                            <Col xs={3} className='mb-2'>
                                                <Label for="purchase_from"> Company*</Label>
                                                <Select options={companyOpt} onChange={opt => setCompany(opt)} placeholder="Select Company" required />
                                            </Col>
                                            <Col xs={3} className='mb-2'>
                                                <Label for="purchase_from"> Transpoter Name*</Label>
                                                <Select options={transOpt} onChange={opt => setTranspoter(opt)} placeholder="Select Transpoter" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Label for="purchase_from"> Date From*</Label>
                                                <Input type="Date" value={dateFrm} onChange={e => setDateFrm(e.target.value)} placeholder="Select Date" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Label for="purchase_from"> Date To*</Label>
                                                <Input type="Date" value={dateTo} onChange={e => setDateTo(e.target.value)} placeholder="Select Date" required />
                                            </Col>
                                            <Col xs={2} className='mb-2'>
                                                <Button className='text-white mt-4' color='primary'> Find </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "60px"
                                            },
                                            {
                                                name: 'Payment Date',
                                                selector: row => row.p_date,
                                                wrap: true
                                            },
                                            {
                                                name: 'Transpoter Name',
                                                selector: row => row.customer,
                                            },
                                            {
                                                name: 'Company',
                                                selector: row => row.company,
                                            },
                                            {
                                                name: 'Transaction Type',
                                                selector: row => row.trns_type,
                                            },
                                            {
                                                name: 'Payment For',
                                                selector: row => row.payment_for,
                                            },
                                            {
                                                name: 'Payment Type',
                                                selector: row => row.payment_type,
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amount,
                                            },

                                        ]}
                                        data={tableData2}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                </CardBody>
                            </Card>
                        </div>
                    }
                </Col>
            </Row>
        </Page >
    )
}

export default PaymentReport;