import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import { MdDeleteForever } from 'react-icons/md';

import {
    Row, Col, Card, CardBody, Input, InputGroup, InputGroupText, Button, Spinner, Label, Modal, ModalHeader, ModalBody, Form,
    CardHeader
} from 'reactstrap';

import { FaSearch, FaEdit } from 'react-icons/fa';

const PaymentList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(1);
    const [tableData, setTableData] = useState('');
    const [tableData2, setTableData2] = useState('');
    const [filterData, setFilterData] = useState('');

    const [editModal, setEditModal] = useState(false);
    const [companyOpt, setCompanyOpt] = useState([]);
    const [editRow, setEditRow] = useState('');
    const [edCompany, setEdCompany] = useState('');
    const [edPaymentType, setEdPaymentType] = useState('');
    const [edPaymentDate, setEdPaymentDate] = useState('');
    const [edTransType, setEdTransType] = useState('');
    const [edPaymentFor, setEdPaymentFor] = useState('');
    const [edAmount, setEdAmount] = useState('');

    useEffect(() => {
        get_customer_data();

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return <option key={index} value={row.company_code} >{row.name}</option>
                });
                setCompanyOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }, []);

    const get_customer_data = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return {
                        sl: index + 1,
                        customer_name: row.customer_name,
                        company: row.company_name,
                        trns_type: row.trns_type == 1 ? "Credit" : "Debit",
                        payment_for: row.payment_for,
                        payment_type: row.payment_type == 1 ? "Online" : "Cash",
                        amount: 'Rs. ' + Number(row.amount).toFixed(2),
                        payment_date: row.date.split(" ")[0].split('-').reverse().join('-'),
                        action: <>
                            <FaEdit className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
                            <MdDeleteForever className='cursor ms-3' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                        </>
                    }
                })
                setTableData(list);
                setFilterData(list);
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        // 
    }

    const get_transpoter_data = () => {
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_transpoter_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return {
                        sl: index + 1,
                        transpoter_name: row.transpoter_name,
                        company: row.company_name,
                        trns_type: row.trns_type == 1 ? "Credit" : "Debit",
                        payment_for: row.payment_for,
                        payment_type: row.payment_type == 1 ? "Online" : "Cash",
                        amount: 'Rs. ' + Number(row.amount).toFixed(2),
                        payment_date: row.date.split(" ")[0].split('-').reverse().join('-'),
                        action: <>
                            <FaEdit className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
                            <MdDeleteForever className='cursor ms-3' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                        </>
                    }
                })
                setTableData2(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }

    const filter_data_handler = (e) => {
        let fData = tableData.filter(item => {
            const query = e.target.value.toLowerCase();
            return (
                item.customer_name.toLowerCase().indexOf(query) >= 0 ||
                item.company.toLowerCase().indexOf(query) >= 0 ||
                item.trns_type.toLowerCase().indexOf(query) >= 0 ||
                item.payment_for.toLowerCase().indexOf(query) >= 0 ||
                item.payment_date.toLowerCase().indexOf(query) >= 0
            )
        })
        setFilterData(fData);

    }

    const open_edit_handler = (row) => {

        setEdCompany(row.company_id);
        setEdTransType(row.trns_type);
        setEdPaymentType(row.payment_type);
        setEdPaymentFor(row.payment_for);
        setEdPaymentDate(row.date);
        setEdAmount(row.amount);

        setEditRow(row.payment_id);

        setEditModal(true);
    }

    const submit_edit_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {
            "payment_id": editRow,

            "company_id": edCompany,
            "trns_type": edTransType,
            "payment_type": edPaymentType,
            "payment_for": edPaymentFor,
            "date": edPaymentDate,
            "amount": edAmount,
        }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/payment_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                setLoading(false);
                setEditModal(false);

                if(mode === 1){
                    get_customer_data();
                }else{
                    get_transpoter_data();
                }
                
                let notify = notification({ message: "Update successful", type: 'success' });
                notify();
                
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete this Payment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "payment_id": row.payment_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/payment_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Payment deleted successfully", type: 'success' });
                                notify();
                                
                                if(mode === 1){
                                    get_customer_data();
                                }else{
                                    get_transpoter_data();
                                }

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Payment List</h5>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Card>
                        <CardHeader>
                            <Button className='text-white me-3' color='primary' onClick={() => { setMode(1); get_customer_data(); }}> Customer </Button>
                            <Button className='text-white me-3' color='primary' onClick={() => { setMode(2); get_transpoter_data(); }}> Transpoter </Button>
                        </CardHeader>
                        <CardBody>
                            {
                                mode === 1 &&

                                <DataTable
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'Sl.no',
                                            selector: row => row.sl,
                                            width: "60px"
                                        },
                                        {
                                            name: 'Customer Name',
                                            selector: row => row.customer_name,
                                            wrap: true
                                        },
                                        {
                                            name: 'Company',
                                            selector: row => row.company,
                                            wrap: true
                                        },
                                        {
                                            name: 'Transaction Type',
                                            selector: row => row.trns_type,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment For',
                                            selector: row => row.payment_for,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment Type',
                                            selector: row => row.payment_type,
                                            wrap: true
                                        },
                                        {
                                            name: 'Amount',
                                            selector: row => row.amount,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment Date',
                                            selector: row => row.payment_date,
                                            wrap: true
                                        },
                                        {
                                            name: 'Action',
                                            selector: row => row.action,
                                            wrap: true
                                        },


                                    ]}
                                    data={filterData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                />
                            }
                            {
                                mode === 2 &&

                                <DataTable
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'Sl.no',
                                            selector: row => row.sl,
                                            width: "60px"
                                        },
                                        {
                                            name: 'Transpoter Name',
                                            selector: row => row.transpoter_name,
                                            wrap: true
                                        },
                                        {
                                            name: 'Company',
                                            selector: row => row.company,
                                            wrap: true
                                        },
                                        {
                                            name: 'Transaction Type',
                                            selector: row => row.trns_type,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment For',
                                            selector: row => row.payment_for,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment Type',
                                            selector: row => row.payment_type,
                                            wrap: true
                                        },
                                        {
                                            name: 'Amount',
                                            selector: row => row.amount,
                                            wrap: true
                                        },
                                        {
                                            name: 'Payment Date',
                                            selector: row => row.payment_date,
                                            wrap: true
                                        },
                                        {
                                            name: 'Action',
                                            selector: row => row.action,
                                            wrap: true
                                        },


                                    ]}
                                    data={tableData2}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                />
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModal}
                toggle={() => setEditModal(!editModal)}
            >
                <ModalHeader toggle={() => setEditModal(!editModal)}>
                    Edit
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={submit_edit_handler}>
                        <Row>
                            <Col xs={6} className='mb-2'>
                                <Label for="Company"> Company </Label>
                                <Input id="Company" name="Company" placeholder="Company" type="select" value={edCompany} onChange={(e) => setEdCompany(e.target.value)} required >
                                    <option value="0"> --Select Company-- </option>
                                    {companyOpt}
                                </Input>
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label> Transaction Type </Label>
                                <Input id="Company" name="Company" placeholder="Company" type="select" value={edTransType} onChange={(e) => setEdTransType(e.target.value)} required >
                                    <option value="0"> --Select Transaction Type-- </option>
                                    <option value="1"> Credit </option>
                                    <option value="2"> Debit </option>
                                </Input>
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label> Payment Type </Label>
                                <Input id="Company" name="Company" placeholder="Company" type="select" value={edPaymentType} onChange={(e) => setEdPaymentType(e.target.value)} required >
                                    <option value="0"> --Select Payment Type-- </option>
                                    <option value="1"> On-Line </option>
                                    <option value="2"> Cash </option>
                                </Input>
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label> Payment For </Label>
                                <Input placeholder="Payment For" type="text" value={edPaymentFor} onChange={(e) => setEdPaymentFor(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label> Payment Date </Label>
                                <Input placeholder="Payment Date" type="date" value={edPaymentDate} onChange={(e) => setEdPaymentDate(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label> Amount </Label>
                                <Input id="amt" name="amt" placeholder="Amount" type="number" min="1" value={edAmount} onChange={(e) => setEdAmount(e.target.value)} />
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > Update {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Page >
    )
}

export default PaymentList;