import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Page from '../../component/layout/Page';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import {
    Row, Col, Card, CardBody, Button, Input, Label, Form
} from 'reactstrap';
import Select from 'react-select'


const Payment = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(1);
    const [custOpt, setCustOpt] = useState('');
    const [transOpt, setTransOpt] = useState('');
    const [companyOpt, setCompanyOpt] = useState('');

    const [company, setCompany] = useState('0');
    const [customer, setCustomer] = useState('0');
    const [transpoter, setTranspoter] = useState('0');
    const [transType, setTransType] = useState('0');
    const [paymentType, setPaymentType] = useState('0');
    const [paymentFor, setPaymentFor] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [cashDiscount, setCashDiscount] = useState('0');
    const [claimAmount, setClaimAmount] = useState('0');
    const [amount, setAmount] = useState('0');
    const [total, setTotal] = useState('0');
    const [gst, setGst] = useState('');

    useEffect(() => {
        get_init();
    }, []);

    const get_init = () => {

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/company_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_1 = response.data.map((row, index) => {
                    return <option key={index} value={row.company_code} >{row.name}</option>
                });
                setCompanyOpt(list_1);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
        
       
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list_4 = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.customer_id, label: row.name, gst: row.gst_no } 
                });
                setCustOpt(list_4);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
         //  
         axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.filter((el)=>el.status == 1).map((row, index) => {
                    return {value: row.transport_master_id, label: row.transport_master_name, gst: row.gstin } 
                });
                setTransOpt(list);
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //  
       
    }

    const payment_entry_handler = (e) => {
        e.preventDefault();
        let valid = true;

        let postData = {
            "user_type": type,
            "customer_id": type === 1 ? customer : transpoter,
            "trns_type": transType,
            "company_id": company,
            "amount": amount,
            "payment_type": paymentType,
            "payment_for": paymentFor,
            "date": paymentDate
        }
        console.log(postData)

        if(valid){
            axios({
                method: 'post',
                url: mainUrl + 'ProductMaster/payment_customer',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    console.log(response.data);
                    navigate('/Payment-list')
                    let notify = notification({ message: "Submit successfully", type: 'success' });
                    notify();
                    
    
                }).catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                })
        }
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>

                    <h5 className='text-primary'>Payment Page</h5>
                    <Card>
                        <CardBody>
                            <Form onSubmit={payment_entry_handler}>
                                <Row>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Customer / Transpoter</Label>
                                        <Select options={[
                                            {value: 1, label: 'Customer'},
                                            {value: 2, label: 'Transpoter'},
                                        ]} onChange={opt => setType(opt.value)} placeholder="Select Type" required />
                                    </Col>
                                    {
                                        type === 1 ? 
                                        <Col xs={4} className='mb-2'>
                                            <Label> Customer Name</Label>
                                            <Select options={custOpt} onChange={opt => {setCustomer(opt.value); setGst(opt.gst)}} placeholder="Select Customer" required />
                                        </Col>
                                        :
                                        <Col xs={4} className='mb-2'>
                                            <Label> Transpoter Name</Label>
                                            <Select options={transOpt} onChange={opt => {setTranspoter(opt.value); setGst(opt.gst)}} placeholder="Select Transpoter" required />
                                        </Col>
                                    }
                                    <Col xs={4} className='mb-2'>
                                        <Label for="gst"> GST Number </Label>
                                        <Input id="gst" placeholder="GST Number" type="text" value={gst} readOnly />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label for="Company"> Company </Label>
                                        <Input id="Company" name="Company" placeholder="Company" type="select" value={company} onChange={(e) => setCompany(e.target.value)} required >
                                            <option value="0"> --Select Company-- </option>
                                            {companyOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Transaction Type </Label>
                                        <Input id="Company" name="Company" placeholder="Company" type="select" value={transType} onChange={(e) => setTransType(e.target.value)} required >
                                            <option value="0"> --Select Transaction Type-- </option>
                                            <option value="1"> Credit </option>
                                            <option value="2"> Debit </option>
                                        </Input>
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Payment Type </Label>
                                        <Input id="Company" name="Company" placeholder="Company" type="select" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} required >
                                            <option value="0"> --Select Payment Type-- </option>
                                            <option value="1"> On-Line </option>
                                            <option value="2"> Cash </option>
                                        </Input>
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Payment For </Label>
                                        <Input placeholder="Payment For" type="text" value={paymentFor} onChange={(e) => setPaymentFor(e.target.value)} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Payment Date </Label>
                                        <Input placeholder="Payment Date" type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} required />
                                    </Col>
                                    <Col xs={4} className='mb-2'>
                                        <Label> Amount </Label>
                                        <Input id="amt" name="amt" placeholder="Amount" type="number" min="1" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                    </Col>
                                    
                                    <Col xs={12} className='mb-2 text-end'>
                                        <Button className='text-white mt-4' block color='primary'> Submit </Button>
                                    </Col>
                                </Row>
                            </Form>


                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Page >
    )
}

export default Payment;