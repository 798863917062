
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../component/hocs/notification';
import axios from 'axios';
import mainUrl from '../MainUrl';
import { FaUnlockAlt } from 'react-icons/fa';

import {
  Col, Row, Button, Form, FormGroup, Input, Label, Spinner
} from 'reactstrap';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    let postData = {
      "user_name": userId,
      "password": password,
    };

    axios({
      method: 'post',
      url: mainUrl + 'ProductMaster/user_login',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        if (response.data != null) {
          let msg = `Welcome to Bubu Trading Co.`;
          let notify = notification({ message: msg, type: 'success' });
          notify();
          sessionStorage.setItem('logging', 111);
          sessionStorage.setItem('logData', JSON.stringify(response.data[0]));
          navigate('/home');
        } else {
          sessionStorage.clear();
          let notify = notification({ message: "User name and password are not matched! Try Again", type: 'error' });
          notify();
        }
        setLoading(false);

      }).catch(error => {
        setLoading(false);
        let notify = notification({ message: "Error in login! Try Again", type: 'error' });
        notify();
      })
  };

  return (
    <Row className="d-flex justify-content-center align-items-center br-5 loginPage">
      <Col md={4} className="p-3 rounded" >
        <div className='text-center mb-3'>
          <h4 className='text-white'>Stock Management System</h4>
          <FaUnlockAlt size={40} color="#fff" />
        </div>
        <Form className="mb-3" onSubmit={handleSubmit}>
          <FormGroup>
            <Input bsSize="lg" type="text" name='userName' placeholder='User name' onChange={(e) => setUserId(e.target.value)} required />
          </FormGroup>
          <FormGroup>
            <Input bsSize="lg" type="password" name='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
          </FormGroup>
          <Button
            className='text-white'
            size="sm"
            color="secondary"
            block
            disabled={loading}
            type="submit">Login <span>{loading && <Spinner color="secondary" size="sm" >Load...</Spinner>}</span>
          </Button>
        </Form>
      </Col>

      <div
        style={{
          position: 'fixed',
          bottom: '30px',
          width: '100%',
          textAlign: 'center',
          fontSize: '25px',
          fontWeight: 'bold',
          color: '#fff',
          fontFamily: 'cursive'

        }}
      >
        Bubutrading
      </div>


    </Row>
  );
}

export default Login;
