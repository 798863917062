import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import Page from '../../component/layout/Page';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import PdfGenerator from '../../component/utility/PdfGenerator';

import {
    Row, Col, Card, CardBody, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody,
    CardHeader
} from 'reactstrap';

import { FaSearch } from 'react-icons/fa';
import { FaFilePdf } from "react-icons/fa6";
import { MdDeleteForever, MdOpenInBrowser } from 'react-icons/md';

const InvoiceList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);
    const [stateOpt, setStateOpt] = useState([]);

    const [tableData, setTableData] = useState('');
    const [filterData, setFilterData] = useState('');

    const [pdfData, setPdfData] = useState('');
    const [pdfItems, setPdfItems] = useState([]);

    const [taxableAmt, setTaxableAmt] = useState(0);
    const [cgstAmt, setCgstAmt] = useState(0);
    const [sgstAmt, setSgstAmt] = useState(0);
    const [igstAmt, setIgstAmt] = useState(0);
    const [discountAmt, setDiscountAmt] = useState(0);
    const [otherAmt, setOtherAmt] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    

    useEffect(() => {
        get_invoice_list();

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/state_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return { value: row.state_code, label: row.state_name, dtl: row }
                });
                setStateOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    }, []);

    const get_invoice_list = () => {

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_invoice_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return {
                        sl: index + 1,
                        category: row.category,
                        doc_type: row.doc_type == 'INV' ? "Tax Invoice" : row.doc_type == 'CRN' ? "Credit Note" : row.doc_type == 'DBN' ? "Debit Note" : "Bill of supply",
                        doc_no: row.doc_no,
                        doc_date: row.doc_date.split('-').reverse().join('-'),
                        customer_name: row.customer_name,
                        customer_gst: row.gst_no,
                        place_of_suppli: row.place_of_sup_name,
                        status: row.status === "0" ? "Waiting for approval" : row.status === "1" ? <span className='text-success'>Accepted</span> : <span className='text-danger'>Rejected</span>,
                        action: <>
                             <MdDeleteForever className='cursor' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                             {
                                (row.status === "0") &&
                                <MdOpenInBrowser className='cursor ms-3' color='#0188c7' size={22} onClick={() => status_change_handler(row)} />
                            }

                            <FaFilePdf className='cursor ms-3' color='#fb3a3b' size={22} onClick={() => genrate_pdf_handler(row.invoice_header_id)} />


                        </>
                    }
                })
                setTableData(list);
                setFilterData(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        // 
    }

    const filter_data_handler = (e) => {
        let fData = tableData.filter(item => {
            const query = e.target.value.toLowerCase();
            return (
                item.category.toLowerCase().indexOf(query) >= 0 ||
                item.doc_type.toLowerCase().indexOf(query) >= 0 ||
                item.doc_no.toLowerCase().indexOf(query) >= 0 ||
                item.doc_date.toLowerCase().indexOf(query) >= 0 ||
                item.customer_name.toLowerCase().indexOf(query) >= 0 ||
                item.place_of_suppli.toLowerCase().indexOf(query) >= 0 
            )
        })
        setFilterData(fData);

    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete this Invoice?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "invoice_id": row.invoice_header_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/invoice_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Invoice deleted successfully", type: 'success' });
                                notify();
                                get_invoice_list();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });

    }
    const status_change_handler = (row) => {

        confirmAlert({
            title: 'Accept or Reject',
            message: 'Accept or Reject this invoice?',
            buttons: [
                {
                    label: 'Accept',
                    onClick: () => {
                        let postData = { "invoice_header_id": row.invoice_header_id, "status": 1 };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/invoice_status_change',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Invoice Accepted successfully", type: 'success' });
                                notify();
                                get_invoice_list();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'Reject',
                    onClick: () => {
                        let postData = { "invoice_header_id": row.invoice_header_id, "status": 2 };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/invoice_status_change',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Invoice Rejected successfully", type: 'success' });
                                notify();
                                get_invoice_list();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })
                    }
                }
            ]
        });

    }

    const genrate_pdf_handler = (id) => {
        let postData = { "invoice_id": id };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/invoice_pdf',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                setPdfData(response.data.invoice_header[0]);
                setPdfItems(response.data.invoice_product);

                let taxable_amount = 0, cgst_amount = 0, sgst_amount = 0, igst_amount = 0, discount = 0, other_charges = 0, total_amount = 0;
                response.data.invoice_product?.map(row => {
                    taxable_amount = taxable_amount + Number(row.taxable_amount);
                    cgst_amount = cgst_amount + Number(row.cgst_amount);
                    sgst_amount = sgst_amount + Number(row.sgst_amount);
                    igst_amount = igst_amount + Number(row.igst_amount);
                    discount = discount + Number(row.discount);
                    other_charges = other_charges + Number(row.other_charges);
                    total_amount = total_amount + Number(row.total_amount);
                })

                setTaxableAmt(Number(taxable_amount).toFixed(2));
                setCgstAmt(Number(cgst_amount).toFixed(2));
                setSgstAmt(Number(sgst_amount).toFixed(2));
                setIgstAmt(Number(igst_amount).toFixed(2));
                setDiscountAmt(Number(discount).toFixed(2));
                setOtherAmt(Number(other_charges).toFixed(2));
                setTotalAmt(Number(total_amount).toFixed(2));

                setPdfModal(true);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={9} className='mb-1'>
                            <h5 className='text-primary'>Invoice List</h5>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                                <DataTable
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'Sl.no',
                                            selector: row => row.sl,
                                            width: "60px"
                                        },
                                        {
                                            name: 'Category',
                                            selector: row => row.category,
                                        },
                                        {
                                            name: 'Doc Type',
                                            selector: row => row.doc_type,
                                        },
                                        {
                                            name: 'Doc No',
                                            selector: row => row.doc_no,
                                        },
                                        {
                                            name: 'Doc Date',
                                            selector: row => row.doc_date,
                                            wrap: true
                                        },
                                        {
                                            name: 'Customer Name',
                                            selector: row => row.customer_name,
                                            wrap: true
                                        },
                                        {
                                            name: 'Customer GSTIN',
                                            selector: row => row.customer_gst,
                                        },
                                        {
                                            name: 'Place Of Supply',
                                            selector: row => row.place_of_suppli,
                                        },
                                        {
                                            name: 'Status',
                                            selector: row => row.status,
                                        },
                                        {
                                            name: 'Action',
                                            selector: row => row.action,
                                        },

                                    ]}
                                    data={filterData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                centered
                scrollable
                size="xl"
                isOpen={pdfModal}
                toggle={() => setPdfModal(!pdfModal)}
            >
                <ModalHeader toggle={() => setPdfModal(!pdfModal)}>
                    Invoice PDF
                </ModalHeader>
                <ModalBody className='' >
                    {
                        pdfData.doc_type === 'BOS' ? 
                        <PdfGenerator content={
                            <div className='mt-4 m-2'>
                                <Row className={pdfData.company_id == 101 ? 'bgpink' : 'bggreen'}  style={{border: '2px solid #000'}}>
                                    <div className='mb-3 mt-3 text-center'><h6>PURCHASE BILL OF SUPPLY</h6></div>
                                    <Col xs={12}>
                                        <h5>{pdfData.company_id == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</h5>
                                        <div>{pdfData.company_id == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                        <div>{pdfData.company_id == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                        <div>WEST BENGAL - 721445</div>
                                        <div>Phone No. : 9733641119</div>
                                    </Col>
                                    <hr/>
                                    <hr/>
                                    <Col xs={6}>
                                        <div><b>Place of Supply : </b>{pdfData.address1}  {pdfData.address2 ? pdfData.address2 : ''}</div>
                                        <div><b>GSTIN : </b> {pdfData.company_id == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</div>
                                        <div><b>Document No. : </b>  {pdfData.doc_no}</div>
                                        <div><b>Document Date : </b>  {pdfData.doc_date?.split('-').reverse().join('/')}</div>
                                        <div><b>Way Bill No. : </b>  {pdfData.transport_doc_no}</div>
                                        <div><b>Vehicle No. : </b>  {pdfData.vehical_no}</div>

                                    </Col>
                                    { 
                                    pdfData.customer_id == 0 ?
                                        <Col xs={6}>
                                            <div><b>Vendor GSTIN : </b> {pdfData.cust_gstin}</div>
                                            <div><b>Vendor Name : </b>{pdfData.customer_name}</div>
                                            <div><b>Vendor Address : </b>{pdfData.cust_address_line1}  {pdfData.cust_address_line2 ? pdfData.cust_address_line2 : ''}</div>
                                            <div><b>State : </b>{pdfData.cust_state} - {pdfData.cust_pincode}</div>
                                            <div><b>Phone No. : </b>{pdfData.cust_ph_no}</div>
                                            <div><b>Verndor Type : UNREGISTERED DEALER</b></div>
                                        </Col>
                                    :
                                    
                                        <Col xs={6}>
                                            <div><b>Vendor GSTIN : </b> {pdfData.gst_no}</div>
                                            <div><b>Vendor Name : </b>{pdfData.name}</div>
                                            <div><b>Vendor Address : </b>{pdfData.address1}  {pdfData.address2 ? pdfData.address2 : ''}</div>
                                            <div><b>State : </b>{pdfData.state_name} - {pdfData.pincode}</div>
                                            <div><b>Phone No. : </b>{pdfData.phone_no}</div>
                                            <div><b>Verndor Type : UNREGISTERED DEALER</b></div>
                                        </Col>

                                    }
                                    <hr/>
                                    <div className='pdf_body'>
                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>#</b></td>
                                                        <td><b>Product Decription</b></td>
                                                        <td><b>HSN</b></td>
                                                        <td><b>Qty</b></td>
                                                        <td><b>Rate</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Amount</b></td>
                                                        <td><b>GST(%)</b></td>
                                                        <td><b>IGST</b></td>
                                                        <td><b>SGST</b></td>
                                                        <td><b>CGST</b></td>
                                                        <td><b>Oth Charge</b></td>
                                                        <td><b>Item Total</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        pdfItems?.map((row, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.product_name}</td>
                                                                    <td>{row.product_code}</td>
                                                                    <td>{row.qty} {row.unit}</td>
                                                                    <td>{row.rate}</td>
                                                                    <td>{row.discount}</td>
                                                                    <td>{row.taxable_amount}</td>
                                                                    <td>{row.gst}</td>
                                                                    <td>{row.igst_amount}</td>
                                                                    <td>{row.sgst_amount}</td>
                                                                    <td>{row.cgst_amount}</td>
                                                                    <td>{row.other_charges}</td>
                                                                    <td>{row.taxable_amount}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            <h6><b>TAX Under REVERSE CHARGE</b></h6>

                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>Tax'ble Amt</b></td>
                                                        <td><b>CGST Amount</b></td>
                                                        <td><b>SGST Amount</b></td>
                                                        <td><b>IGST Amount</b></td>
                                                        <td><b>CESS Amount</b></td>
                                                        <td><b>State CESS Amount</b></td>
                                                        <td><b>Total Taxes</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Other Charges</b></td>
                                                        <td><b>Total Invoice Amount</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{taxableAmt}</td>
                                                        <td>{cgstAmt}</td>
                                                        <td>{sgstAmt}</td>
                                                        <td>{igstAmt}</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>{Number(Number(cgstAmt) + Number(sgstAmt) + Number(igstAmt)).toFixed(2)}</td>
                                                        <td>{discountAmt}</td>
                                                        <td>{otherAmt}</td>
                                                        <td>{taxableAmt}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                </Row>

                                <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>


                            </div>
                        } />

                        : pdfData.doc_type === 'CHA' ? <PdfGenerator content={
                            <div>
                                <div className='mb-3 mt-4 text-center'><h4>CHALLAN</h4></div>
                                <Row className={pdfData.company_id == 101 ? 'bgpink' : 'bggreen'} style={{ border: '2px solid #000' }}>
                                    <Col xs="4"><b>GSTIN: </b> {pdfData.company_id == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</Col>
                                    <Col xs="4" className='text-center'><h6>{pdfData.challan_type == "1" ? "PURCHASE BILL" : "SALE BILL"}</h6></Col>
                                    <Col xs="4" className='text-end'><b>ORIGINAL</b></Col>
                                    <Col xs={12} className='text-center'>
                                        <h5>{pdfData.company_id == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</h5>
                                        <div>{pdfData.company_id == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                        <div>{pdfData.company_id == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                        <div>WEST BENGAL - 721445</div>
                                        <div>Phone No. : 9733641119</div>
                                    </Col>
                                    <hr />
                                    <Col xs="4"><b>Bill NO.: </b> {pdfData.doc_no}</Col>
                                    <Col xs="4"><b>Bill Date: </b> {pdfData.doc_date?.split('-').reverse().join('/')}</Col>
                                    <Col xs="4"><b>Vehicle No.: </b> {pdfData.vehical_no}</Col>
                                    <hr />
                                    <Col xs={12}>
                                        <div><b>Vendor Name : </b> {pdfData.name}</div>
                                        <div><b>Vendor Address : </b> {pdfData.address1}  {pdfData.address2 ? pdfData.address2 : ''}, {pdfData.state_name} - {pdfData.pincode} </div>
                                        <div><b>Phone No.: </b> {pdfData.phone_no}</div>
                                    </Col>
                                    <hr />
                                    <div className='pdf_body'>
                                        <table className='mb-3' border="1" width="100%">
                                            <thead>
                                                <tr>
                                                    <td><b>Sl.No.</b></td>
                                                    <td><b>Product Decription</b></td>
                                                    <td><b>HSN</b></td>
                                                    <td><b>Qty</b></td>
                                                    <td><b>Rate</b></td>
                                                    <td><b>Discount</b></td>
                                                    <td><b>Amount</b></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    pdfItems.map((row, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{row.product_name}</td>
                                                                <td>{row.product_code}</td>
                                                                <td>{row.qty} {row.unit}</td>
                                                                <td>{row.rate}</td>
                                                                <td>{row.discount}</td>
                                                                <td>{row.taxable_amount}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div className=' mb-3 text-end'><b>Total Amount: </b> {Number(taxableAmt).toFixed(2)}</div>
                                    </div>
                                </Row>
                                <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>

                            </div>
                        } />

                        :
                        
                        <PdfGenerator content={
                            <div>
                                <div className='mb-3 mt-4 text-center'><h4>INVOICE</h4></div>

                                <Row>
                                    <Col xs={12}>
                                        <div className='pdf_header'>Document Details</div>
                                        <div className='pdf_body'>
                                            <Row>
                                                <Col xs={4} className="mb-3">
                                                    Category :  {pdfData.category}
                                                </Col>
                                                <Col xs={4} className="mb-3">
                                                    Document No. :  {pdfData.doc_no}
                                                </Col>
                                                <Col xs={4} className="mb-3">
                                                    IGST on INTRA :  {pdfData.igst_intra == 'N' ? "No" : 'Yes'}
                                                </Col>
                                                <Col xs={4} className="mb-3">
                                                    Document Type :  {pdfData.doc_type == 'INV' ? "Tax Invoice" : pdfData.doc_type == 'CRN' ? "Credit Note" : pdfData.doc_type == 'DBN' ? "Debit Note" : "Bill of supply"}
                                                </Col>
                                                <Col xs={4} className="mb-3">
                                                    Document Date :  {pdfData.doc_date?.split('-').reverse().join('/')}
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className='pdf_header'>Party Details</div>
                                        <div className='pdf_body'>
                                            <Row>
                                                <Col xs={6} className="mb-3" >
                                                    <div className='p-3 borderBlack h-100'>
                                                        <div><b>Seller</b></div>
                                                        <div>GSTIN : {pdfData.company_id == 101 ? "19CDYPM3446F1ZY" : "19AMTPM0057A1Z3"}</div>

                                                        <div>{pdfData.company_id == 101 ? "BUBU TRADING CO." : "MOUMI TRADING CO."}</div>
                                                        <div>{pdfData.company_id == 101 ? "VILL+P.O-PURUNDA,DANTAN, PASCHIM MEDINIPUR" : "PLOT. NO.-423/178 KH.NO.-42,J"}</div>
                                                        <div>{pdfData.company_id == 101 ? '' : "PURUNDA,DANTAN, PASCHIM MEDINIPUR"}</div>
                                                        <div>WEST BENGAL - 721445</div>
                                                        <div>9733641119</div>
                                                    </div>
                                                </Col>
                                                <Col xs={6} className="mb-3">
                                                    <div className='p-3 borderBlack h-100'>
                                                        <div><b>Purchaser</b></div>
                                                        <div>GSTIN : {pdfData.gst_no}</div>
                                                        <div>{pdfData.name}</div>
                                                        <div>{pdfData.address1}</div>
                                                        <div>{pdfData.address2 ? pdfData.address2 : ''}</div>
                                                        <div>{pdfData.state_name} - {pdfData.pincode}</div>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>{pdfData.phone_no}</div>
                                                            <div><b>Place of Supply : </b>{stateOpt.filter(opt => opt.value === pdfData.place_of_suppli)?.[0]?.label}</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                {pdfData.dis_name &&

                                                    <Col xs={6} className="mb-3">
                                                        <div className='p-3 borderBlack h-100'>
                                                            <div><b>Dispatch From</b></div>
                                                            <div>{pdfData.dis_name}</div>
                                                            <div>{pdfData.dis_addr1}</div>
                                                            <div>{pdfData.dis_addr2 ? pdfData.dis_addr2 : ''}</div>
                                                            <div>{stateOpt.filter(opt => opt.value === pdfData.dis_state)?.[0]?.label} - {pdfData.dis_pin}</div>

                                                        </div>
                                                    </Col>
                                                }
                                                {pdfData.ship_name &&

                                                    <Col xs={6} className="mb-3">
                                                        <div className='p-3 borderBlack h-100'>
                                                            <div><b>Ship To</b></div>
                                                            <div>GSTIN : {pdfData.ship_gstin}</div>
                                                            <div>{pdfData.ship_name}</div>
                                                            <div>{pdfData.ship_addr1}</div>
                                                            <div>{pdfData.ship_addr2 ? pdfData.ship_addr2 : ''}</div>
                                                            <div>{stateOpt.filter(opt => opt.value === pdfData.ship_state)?.[0]?.label} - {pdfData.ship_pin}</div>

                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                            
                                        </div>
                                        <div className='pdf_header'>Item Details</div>
                                        <div className='pdf_body'>
                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>#</b></td>
                                                        <td><b>Product Decription</b></td>
                                                        <td><b>HSN</b></td>
                                                        <td><b>Qty</b></td>
                                                        <td><b>Rate</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Amount</b></td>
                                                        <td><b>GST(%)</b></td>
                                                        <td><b>IGST</b></td>
                                                        <td><b>SGST</b></td>
                                                        <td><b>CGST</b></td>
                                                        <td><b>Oth Charge</b></td>
                                                        <td><b>Item Total</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        pdfItems?.map((row, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.product_name}</td>
                                                                    <td>{row.product_code}</td>
                                                                    <td>{row.qty}</td>
                                                                    <td>{row.rate}</td>
                                                                    <td>{row.discount}</td>
                                                                    <td>{row.taxable_amount}</td>
                                                                    <td>{row.gst}</td>
                                                                    <td>{row.igst_amount}</td>
                                                                    <td>{row.sgst_amount}</td>
                                                                    <td>{row.cgst_amount}</td>
                                                                    <td>{row.other_charges}</td>
                                                                    <td>{row.total_amount}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            <table className='mb-3' border="1" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td><b>Tax'ble Amt</b></td>
                                                        <td><b>CGST Amount</b></td>
                                                        <td><b>SGST Amount</b></td>
                                                        <td><b>IGST Amount</b></td>
                                                        <td><b>CESS Amount</b></td>
                                                        <td><b>State CESS Amount</b></td>
                                                        <td><b>Discount</b></td>
                                                        <td><b>Other Charges</b></td>
                                                        <td><b>Total Invoice Amount</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{taxableAmt}</td>
                                                        <td>{cgstAmt}</td>
                                                        <td>{sgstAmt}</td>
                                                        <td>{igstAmt}</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>{discountAmt}</td>
                                                        <td>{otherAmt}</td>
                                                        <td>{totalAmt}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='mt-4 text-center'> This is a computer-generated document. No signature is required. </div>

                                    </Col>
                                </Row>
                            </div>
                        } />
                    }
                   

                </ModalBody>
            </Modal>
        </Page >
    )
}

export default InvoiceList;