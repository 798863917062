import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';

import Page from '../../component/layout/Page';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
    Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Input, Label, Form, Spinner, InputGroup, InputGroupText
} from 'reactstrap';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit, FaSearch } from "react-icons/fa";

const TransportMaster = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [transportModal, setTransportModal] = useState(false);
    const [transportList, setTransportList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [name, setName] = useState('');
    const [gstin, setGstin] = useState('');

    const [transportEditModal, setTransportEditModal] = useState(false);
    const [transportId, setTransportId] = useState('');
    const [edName, setEdName] = useState('');
    const [edGstin, setEdGstin] = useState('');

    useEffect(() => {
        get_transport_list_handler();
    }, []);

    const get_transport_list_handler = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return {
                        sl: index + 1,
                        transport_no: row.transport_master_id,
                        name: row.transport_master_name,
                        gstin: row.gstin,
                        action: <>
                            <Switch checked={row.status == 1 ? true : false} onChange={() => change_status_handler(row)} />
                            <FaEdit className='cursor ms-3' color='#fe9300' size={25} onClick={() => open_edit_handler(row)} />
                            <MdDeleteForever className='cursor ms-3' color='#fb3a3b' size={25} onClick={() => delete_handler(row)} />
                        </>

                    }
                })
                setTransportList(list);
                setFilterData(list);
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const transport_add_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = { "transpoter_name": name, "gstin": gstin, "status": 1 };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                setLoading(false);
                setTransportModal(false);
                let notify = notification({ message: "Transport added successfully", type: 'success' });
                notify();
                get_transport_list_handler();
                setName('');

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const change_status_handler = (row) => {
        let postData;
        if (row.status == 0) {
            postData = { "status": 1, "transpoter_id": row.transport_master_id, "gstin": row.gstin, "transpoter_name": row.transport_master_name }
        } else {
            postData = { "status": 0, "transpoter_id": row.transport_master_id, "gstin": row.gstin, "transpoter_name": row.transport_master_name }
        }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                let notify = notification({ message: "Status changed successfully", type: 'success' });
                notify();
                get_transport_list_handler();

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete ' + row.transport_master_name + ' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "transpoter_id": row.transport_master_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/transport_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Transport deleted successfully", type: 'success' });
                                notify();
                                get_transport_list_handler();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    const open_edit_handler = (row) => {
        setTransportId(row.transport_master_id)
        setEdName(row.transport_master_name);
        setEdGstin(row.gstin);
        setTransportEditModal(true);
    }

    const transport_edit_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = { "transpoter_name": edName, "transpoter_id": transportId, "gstin": edGstin, "status": 1 };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/transport_master_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                setLoading(false);
                setTransportEditModal(false);
                let notify = notification({ message: "Transport Updated successfully", type: 'success' });
                notify();
                get_transport_list_handler();

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const filter_data_handler = (e) => {
        let fData = transportList.filter(item => {
            const query = e.target.value.toLowerCase();
            console.log(query)
            return (
                item.name.toLowerCase().indexOf(query) >= 0 ||
                item.gstin.toLowerCase().indexOf(query) >= 0
            )
        })
        setFilterData(fData);

    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row className='mb-3'>
                        <Col xs="6">
                            <h5 className='text-primary'>Transport Master List</h5>
                        </Col>
                        <Col xs="3" className='text-end'>
                            <Button className='text-white' color='primary' onClick={() => setTransportModal(true)}> Add New Transport </Button>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs={12}>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "80px"
                                            },
                                            {
                                                name: 'Transport No.',
                                                selector: row => row.transport_no,
                                                width: "120px"
                                            },
                                            {
                                                name: 'Transport Name',
                                                selector: row => row.name,
                                                wrap: true
                                            },
                                            {
                                                name: 'GSTIN',
                                                selector: row => row.gstin,
                                                wrap: true
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                wrap: true
                                            },
                                        ]}
                                        data={filterData}
                                        striped
                                        highlightOnHover
                                        pagination
                                        progressPending={loading}

                                    />

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={transportModal}
                toggle={() => setTransportModal(!transportModal)}
            >
                <ModalHeader toggle={() => setTransportModal(!transportModal)}>
                    Add New Transport
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={transport_add_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="customer_name"> Transport Name* </Label>
                                <Input id="customer_name" placeholder="Customer Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="transport_name"> GSTIN </Label>
                                <Input id="transport_name" placeholder="GSTIN" type="text" value={gstin} onChange={(e) => setGstin(e.target.value)} />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <b>Note: </b> (*) Mark field are mandatory.
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={transportEditModal}
                toggle={() => setTransportEditModal(!transportEditModal)}
            >
                <ModalHeader toggle={() => setTransportEditModal(!transportEditModal)}>
                    Edit Transport
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={transport_edit_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="transport_name"> Transport Name </Label>
                                <Input id="transport_name" placeholder="Transport Name" type="text" value={edName} onChange={(e) => setEdName(e.target.value)} required />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <Label for="transport_name"> GSTIN </Label>
                                <Input id="transport_name" placeholder="GSTIN" type="text" value={edGstin} onChange={(e) => setEdGstin(e.target.value)} />
                            </Col>

                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > Update {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Page >
    )
}

export default TransportMaster;