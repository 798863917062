import React, { useEffect, useState } from 'react';
import { notification } from '../hocs/notification';

import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MdDashboard } from 'react-icons/md';
import { FaSignOutAlt, FaListAlt, FaCreditCard, FaFilePdf, FaPowerOff } from 'react-icons/fa';
import { GiPayMoney, GiReceiveMoney, GiHamburgerMenu, GiTakeMyMoney, GiCubes, GiArchiveRegister } from "react-icons/gi";
import { TbReport } from "react-icons/tb";
import { IoMdArrowDropdown } from "react-icons/io";

import userImg from './../../assets/img/user.png';
import { Row, Col, Button, } from 'reactstrap';

const Header = () => {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(()=> {
        //check logging
        let logIn =  sessionStorage.getItem('logging');
        if(logIn === "111"){

        }else{
            let notify = notification({ message: "Unauthorized ! please login", type: 'error' });
            notify();
            navigate("/");
        }
    }, [])

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signout_handler = () => {
        sessionStorage.clear();
        navigate("/");
    }

    return (
        <>
            <div className='headerPage bg-primary text-white'>
                <Row>

                    <Col xs={12} className="p-2">
                        {/* ------------------ In Laptop Menu  -------------------- */}
                        <div className='d-flex justify-content-between align-items-center h-100'>
                            <Button className='ms-3 me-3 text-white' onClick={()=>setOpenDrawer(true)} color='secondary' >
                                <GiHamburgerMenu size={20} /> <b>Menu</b> 
                            </Button>
                            <div className='fs-20'><b>Bubu Trading Co.</b></div>
                            <div className='me-3'>
                                <FaPowerOff className='me-3 cursor' color="#fb3a3b" size={35} onClick={signout_handler} />
                            </div>
                        </div>

                        
                        <Drawer
                            anchor="left"
                            open={openDrawer}
                            onClose={()=>setOpenDrawer(false)}
                        >
                            <div style={{width: "270px", borderRight: '5px solid #fe9300', height: '100vh' }} >
                                <div className='text-center mt-2 mb-2' >
                                    <img src={userImg} alt="user" height="70px" />
                                    <div className='text-center mt-1 text-primary fs-20'> <b>ADMIN</b> </div>
                                </div>

                                <div style={{borderBottom: '3px solid #fe9300'}}></div>
                                
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/home')}>
                                            <ListItemIcon>
                                                <MdDashboard color="#0188c7" size={20} />
                                            </ListItemIcon>
                                            <ListItemText primary="Dashboard" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/customer-master')}>
                                            <ListItemIcon>
                                                <FaListAlt color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Customer Master" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/transport-master')}>
                                            <ListItemIcon>
                                                <FaListAlt color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Transport Master" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/broker-master')}>
                                            <ListItemIcon>
                                                <FaListAlt color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Broker Master" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/product-master')}>
                                            <ListItemIcon>
                                                <FaListAlt color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Product Master" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/sale-entry')}>
                                            <ListItemIcon>
                                                <GiReceiveMoney color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Sale" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/purchase-entry')}>
                                            <ListItemIcon>
                                                <GiPayMoney color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Purchase" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/transaction-list')}>
                                            <ListItemIcon>
                                                <GiTakeMyMoney color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Transaction" />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/stock-master')}>
                                            <ListItemIcon>
                                                <GiCubes color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Stock List" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/Payment')}>
                                            <ListItemIcon>
                                                <FaCreditCard color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Payment" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/Payment-list')}>
                                            <ListItemIcon>
                                                <GiArchiveRegister color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Payment List" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/invoice')}>
                                            <ListItemIcon>
                                                <FaFilePdf color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Invoice" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={()=>navigate('/invoice-list')}>
                                            <ListItemIcon>
                                                <FaFilePdf color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Invoice List" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={handleClick}>
                                            <ListItemIcon>
                                                <TbReport color="#0188c7" size={20} />
                                            </ListItemIcon>                                        
                                            <ListItemText primary="Report" />
                                            <ListItemIcon>
                                                <IoMdArrowDropdown color="#0188c7" size={30} />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>

                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={()=>{handleClose(); navigate('/purchase-report')} }>Purchase Report</MenuItem>
                                        <MenuItem onClick={()=>{handleClose(); navigate('/sale-report')} }>Sale Report</MenuItem>
                                        <MenuItem onClick={()=>{handleClose(); navigate('/invoice-report')} }>Invoice Report</MenuItem>
                                        <MenuItem onClick={()=>{handleClose(); navigate('/payment-report')} }>Payment Report</MenuItem>
                                        <MenuItem onClick={()=>{handleClose(); navigate('/customer-all-report')} }>Customer All Report</MenuItem>
                                        <MenuItem onClick={()=>{handleClose(); navigate('/transport-report')} }>Transport Report</MenuItem>
                                        
                                    </Menu>

                                    <ListItem disablePadding>
                                        <ListItemButton style={{padding: '2px 5px'}} onClick={signout_handler}>
                                            <ListItemIcon>
                                                <FaSignOutAlt size={20} color="#fb3a3b" />
                                            </ListItemIcon>                                        
                                            <ListItemText className='text-danger' primary="Sign-out" />
                                        </ListItemButton>
                                    </ListItem>

                                </List>
                            </div>
                        </Drawer>
                    </Col>

                </Row>
            </div>
        </>
    )
}

export default Header;