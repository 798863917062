import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';

import Page from '../../component/layout/Page';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from 'react-select'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FaSearch } from 'react-icons/fa';

import {
    Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Label, Form, Spinner, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const CustomerMaster = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [stateOpt, setStateOpt] = useState([]);
    const [customerModal, setCustomerModal] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [name, setName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [loc, setLoc] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [orgName, setOrgName] = useState('');
    const [gst, setGst] = useState('');
    const [register, setRegister] = useState(1);
    
    const [customerEditModal, setCustomerEditModal] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [edName, setEdName] = useState('');
    const [edAddress1, setEdAddress1] = useState('');
    const [edAddress2, setEdAddress2] = useState('');
    const [edLoc, setEdLoc] = useState('');
    const [edState, setEdState] = useState('');
    const [edPincode, setEdPincode] = useState('');
    const [edEmail, setEdEmail] = useState('');
    const [edPhone, setEdPhone] = useState('');
    const [edOrgName, setEdOrgName] = useState('');
    const [edGst, setEdGst] = useState('');
    const [edRegister, setEdRegister] = useState(1);

    useEffect(() => {
        get_customer_list_handler();

        get_state_list();
    }, []);

    const get_state_list = () => {
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/state_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return {value:row.state_code, label: row.state_name, dtl:row} 
                });
                setStateOpt(list);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
        //
    } 
    
    const get_customer_list_handler = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/get_customer_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let list = response.data.map((row, index) => {
                    return {
                        sl: index + 1,
                        customer_no: row.customer_id,
                        name: row.name,
                        org_name: row.org_name,
                        gst_no: row.gst_no,
                        address1: row.address1,
                        address2: row.address2,
                        location: row.location,
                        state: row.state,
                        pincode: row.pincode,
                        email: row.email,
                        phone: row.phone_no,
                        flag: row.flag == 1 ? "Register" : "Un-Register",
                        action: <>
                            <Switch checked={row.status == 1 ? true : false} onChange={() => change_status_handler(row)} />
                            <FaEdit className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
                            <MdDeleteForever className='cursor ms-1' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                        </>

                    }
                })
                setCustomerList(list);
                setFilterData(list);
                setLoading(false);

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const customer_add_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = { 
            "name": name, 
            "address1": address1, 
            "address2": address2, 
            "location": loc, 
            "state": state, 
            "pincode": pincode, 
            "email": email, 
            "phone": phone, 
            "gst_no": gst, 
            "org_name": orgName, 
            "flag": register 
        };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/customer_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                setLoading(false);
                setCustomerModal(false);
                let notify = notification({ message: "Customer added successfully", type: 'success' });
                notify();
                get_customer_list_handler();
                setName('');
                setAddress1('');
                setAddress2('');
                setPhone('');

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const change_status_handler = (row) => {
        let postData;
        if (row.status == 0) {
            postData = { "status": 1, "customer_id": row.customer_id }
        } else {
            postData = { "status": 0, "customer_id": row.customer_id }
        }

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/customer_status',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                let notify = notification({ message: "Status changed successfully", type: 'success' });
                notify();
                get_customer_list_handler();

            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const delete_handler = (row) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure you want to delete '+  row.name +' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let postData = { "customer_id": row.customer_id };

                        axios({
                            method: 'post',
                            url: mainUrl + 'ProductMaster/customer_delete',
                            data: postData,
                            headers: { 'Content-Type': 'application/JSON', }
                        })
                            .then(function (response) {

                                let notify = notification({ message: "Customer deleted successfully", type: 'success' });
                                notify();
                                get_customer_list_handler();

                            }).catch(error => {
                                setLoading(false);
                                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                                notify();
                            })

                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });

    }

    const open_edit_handler = (row) => {
        setCustomerId(row.customer_id)               
        setEdName(row.name);
        setEdOrgName(row.org_name);
        setEdAddress1(row.address1);
        setEdAddress2(row.address2);
        setEdLoc(row.location);
        setEdState(row.state);
        setEdPincode(row.pincode);
        setEdEmail(row.email);
        setEdPhone(row.phone_no);
        setEdGst(row.gst_no);
        setEdRegister(row.flag);
        setCustomerEditModal(true);
    }

    const customer_edit_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = { 
            "name": edName, 
            "address1": edAddress1, 
            "address2": edAddress2, 
            "location": edLoc, 
            "state": edState, 
            "pincode": edPincode, 
            "email": edEmail,
            "phone": edPhone, 
            "gst_no": edGst, 
            "org_name": edOrgName, 
            "flag": edRegister, 
            "customer_id": customerId };

        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/customer_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                setLoading(false);
                setCustomerEditModal(false);
                let notify = notification({ message: "Customer Updated successfully", type: 'success' });
                notify();
                get_customer_list_handler();
                
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const filter_data_handler = (e) => {
        let fData = customerList.filter (item => {
            const query = e.target.value.toLowerCase();
            console.log(query)
            return (
                item.name.toLowerCase().indexOf(query) >= 0 ||
                item.org_name.toLowerCase().indexOf(query) >= 0 ||
                item.location.toLowerCase().indexOf(query) >= 0 
            )
        })
        setFilterData(fData);

    }

    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row className='mb-3'>
                        <Col xs="6">
                            <h5 className='text-primary'>Customer Master List</h5>
                        </Col>
                        <Col xs="3" className='text-end'>
                            <Button className='text-white' color='primary' onClick={() => setCustomerModal(true)}> Add New Customer </Button>
                        </Col>
                        <Col xs={3} className='text-end mb-1'>
                            <InputGroup>
                                <InputGroupText>
                                    <FaSearch />
                                </InputGroupText>
                                <Input type="text" placeholder='Search' onChange={filter_data_handler} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs={12}>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "50px"
                                            },
                                            {
                                                name: 'Customer Name',
                                                selector: row => row.name,
                                                wrap: true
                                            },
                                            {
                                                name: 'Organization Name',
                                                selector: row => row.org_name,
                                                wrap: true
                                            },
                                            {
                                                name: 'GST No',
                                                selector: row => row.gst_no,
                                                wrap: true
                                            },
                                            {
                                                name: 'Address 1',
                                                selector: row => row.address1,
                                                wrap: true
                                            },
                                            {
                                                name: 'Address 2',
                                                selector: row => row.address2,
                                                wrap: true
                                            },
                                            {
                                                name: 'Location',
                                                selector: row => row.location,
                                                wrap: true
                                            },
                                            {
                                                name: 'State',
                                                selector: row => row.state,
                                                wrap: true
                                            },
                                            {
                                                name: 'Pincode',
                                                selector: row => row.pincode,
                                                wrap: true
                                            },
                                            {
                                                name: 'Phone No',
                                                selector: row => row.phone,
                                                wrap: true
                                            },
                                            {
                                                name: 'Email',
                                                selector: row => row.email,
                                                wrap: true
                                            },
                                            {
                                                name: 'Register Customer',
                                                selector: row => row.flag,
                                                wrap: true
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                wrap: true,
                                                width: '130px'
                                            },
                                        ]}
                                        data={filterData}
                                        striped
                                        highlightOnHover
                                        pagination
                                        progressPending={loading}

                                    />

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={customerModal}
                toggle={() => setCustomerModal(!customerModal)}
            >
                <ModalHeader toggle={() => setCustomerModal(!customerModal)}>
                    Add New Customer
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={customer_add_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="customer_name"> Customer Name*</Label>
                                <Input id="customer_name" placeholder="Customer Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="address"> Address Line - 1* </Label>
                                <Input id="address" placeholder="Address" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="address"> Address Line - 2 </Label>
                                <Input id="address" placeholder="Address" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="Location"> Location* </Label>
                                <Input id="Location" placeholder="Location" type="text" value={loc} onChange={(e) => setLoc(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="state"> State* </Label>
                                <Select 
                                    options = {stateOpt} 
                                    onChange={opt => setState(opt.value) } 
                                    placeholder="Select State" 
                                    required
                                />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="pincode"> Pincode* </Label>
                                <Input id="pincode" placeholder="pincode" type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="phone"> Phone Number </Label>
                                <Input id="phone" placeholder="Phone Number" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="email"> Email </Label>
                                <Input id="email" placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="orgName"> Organization Name </Label>
                                <Input id="orgName" placeholder="Organization Name" type="text" value={orgName} onChange={(e) => setOrgName(e.target.value)} />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="gst"> GST Number* </Label>
                                <Input id="gst" placeholder="GST Number" type="text" value={gst} onChange={(e) => setGst(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <FormControlLabel className='mt-4' control={<Switch checked={register} onChange={(e) => setRegister(!register)} />} label="Register Customer" />
                            </Col>
                            <Col xs={12} className='mb-2'>
                                <b>Note: </b> (*) Mark field are mandatory.
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-2' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={customerEditModal}
                toggle={() => setCustomerEditModal(!customerEditModal)}
            >
                <ModalHeader toggle={() => setCustomerEditModal(!customerEditModal)}>
                    Edit Customer
                </ModalHeader>
                <ModalBody className=''>
                    <Form onSubmit={customer_edit_handler}>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <Label for="customer_name"> Customer Name </Label>
                                <Input id="customer_name" placeholder="Customer Name" type="text" value={edName} onChange={(e) => setEdName(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="address"> Address Line - 1</Label>
                                <Input id="address" placeholder="Address line 1" type="text" value={edAddress1} onChange={(e) => setEdAddress1(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="address"> Address Line - 2</Label>
                                <Input id="address" placeholder="Address line 2" type="text" value={edAddress2} onChange={(e) => setEdAddress2(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="Location"> Location </Label>
                                <Input id="Location" placeholder="Location" type="text" value={edLoc} onChange={(e) => setEdLoc(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="state"> State </Label>
                                <Select 
                                    options = {stateOpt} 
                                    value = {stateOpt.filter(opt => opt.value === edState)}
                                    onChange={opt => setEdState(opt.value) } 
                                    placeholder="Select State" 
                                    required
                                />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="pincode"> Pincode </Label>
                                <Input id="pincode" placeholder="pincode" type="text" value={edPincode} onChange={(e) => setEdPincode(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="phone"> Phone Number </Label>
                                <Input id="phone" placeholder="Phone Number" type="text" value={edPhone} onChange={(e) => setEdPhone(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="email"> Email </Label>
                                <Input id="email" placeholder="Email" type="text" value={edEmail} onChange={(e) => setEdEmail(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="orgName"> Organization Name </Label>
                                <Input id="orgName" placeholder="Organization Name" type="text" value={edOrgName} onChange={(e) => setEdOrgName(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <Label for="gst"> GST Number </Label>
                                <Input id="gst" placeholder="GST Number" type="text" value={edGst} onChange={(e) => setEdGst(e.target.value)} required />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <FormControlLabel className='mt-4' control={<Switch checked={edRegister} onChange={(e) => setEdRegister(!edRegister)} />} label="Register Customer" />
                            </Col>
                            <Col xs={12} className='mb-2 text-end'>
                                <Button className='text-white mt-4' block color='primary' disabled={loading} > Update {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Page >
    )
}

export default CustomerMaster;