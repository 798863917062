
import { Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Home from './pages/Home';
import WIP from './pages/WIP';

// Master Pages

import ProductMaster from "./pages/master/ProductMaster";
import CustomerMaster from "./pages/master/CustomerMaster";
import TransportMaster from "./pages/master/TransportMaster";
import BrokerMaster from "./pages/master/BrokerMaster";
import StockMaster from "./pages/master/StockMaster";
import Purchase from "./pages/master/Purchase";
import Sale from "./pages/master/Sale";
import TransactionList from "./pages/master/TransactionList";
import TransactionView from "./pages/master/TransactionView";
import Payment from "./pages/master/Payment";
import PaymentList from "./pages/master/PaymentList";
import Invoice from "./pages/master/Invoice";
import InvoiceList from "./pages/master/InvoiceList";

// Reports

import PurchaseReport from "./pages/reports/PurchaseReport";
import SaleReport from "./pages/reports/SaleReport";
import InvoiceReport from "./pages/reports/InvoiceReport";
import PaymentReport from "./pages/reports/PaymentReport";
import CustomerAllReport from "./pages/reports/CustomerAllReport";
import TransportReport from "./pages/reports/TransportReport";

import './style/main.scss';

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="*" element={ <WIP /> } />
        <Route path="/" element={ <Login /> } />
        {/* -------------------------- Master Pages -------------------------- */}

        <Route path="/home" element={ <Home /> } />
        <Route path="/product-master" element={ <ProductMaster /> } />
        <Route path="/customer-master" element={ <CustomerMaster /> } />
        <Route path="/transport-master" element={ <TransportMaster /> } />
        <Route path="/broker-master" element={ <BrokerMaster /> } />
        <Route path="/stock-master" element={ <StockMaster /> } />
        <Route path="/purchase-entry" element={ <Purchase /> } />
        <Route path="/sale-entry" element={ <Sale /> } />
        <Route path="/transaction-list" element={ <TransactionList /> } />
        <Route path="/transaction-view" element={ <TransactionView /> } />
        <Route path="/Payment" element={ <Payment /> } />
        <Route path="/Payment-list" element={ <PaymentList /> } />
        <Route path="/invoice" element={ <Invoice /> } />
        <Route path="/invoice-list" element={ <InvoiceList /> } />
        {/* -------------------------- Reports -------------------------- */}

        <Route path="/purchase-report" element={ <PurchaseReport /> } />
        <Route path="/sale-report" element={ <SaleReport /> } />
        <Route path="/payment-report" element={ <PaymentReport /> } />
        <Route path="/invoice-report" element={ <InvoiceReport /> } />
        <Route path="/customer-all-report" element={ <CustomerAllReport /> } />
        <Route path="/transport-report" element={ <TransportReport /> } />
        
      </Routes>
    </div>
  );
}

export default App;