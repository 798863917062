import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';

import Page from '../../component/layout/Page';
import { notification } from '../../component/hocs/notification';
import mainUrl from '../../MainUrl';

import {
    Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Input, Label, Form, Spinner
} from 'reactstrap';
const StockMaster = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [stockList, setStockList] = useState([]);

    useEffect(()=>{
        get_stock_list_handler();
    },[]);

    const get_stock_list_handler = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'ProductMaster/stock_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data); 
                let list = response.data.map((row, index) => {
                    return  {
                        sl: index + 1,
                        product_no: row.product_code,
                        product_name: row.product_name,
                        product_stock: row.stock + " Kg",
                        billing_stock: row.billing_stock + " Kg",
                    }
                })
                setStockList(list); 
                setLoading(false);
      
            }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }
    
    return (

        <Page>
            <Row>
                <Col xs={12}>
                    <Row className='mb-3'>
                        <Col xs="6">
                            <h5 className='text-primary'>Stock Master List</h5>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs={12}>
                                    <DataTable
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'Sl.no',
                                                selector: row => row.sl,
                                                width: "80px"
                                            },
                                            {
                                                name: 'Product Number',
                                                selector: row => row.product_no,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product Name',
                                                selector: row => row.product_name,
                                                wrap: true
                                            },
                                            {
                                                name: 'Product Stock',
                                                selector: row => row.product_stock,
                                            },
                                            {
                                                name: 'Product Billing Stock',
                                                selector: row => row.billing_stock,
                                            },
                                        ]}
                                        data={stockList}
                                        striped
                                        highlightOnHover
                                        pagination
                                        progressPending={loading}

                                    />

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </Page >
    )
}

export default StockMaster;